.search-result{
    padding: 28px 0 112px; 
    @include respond-below(md){
        padding: 50px 0 65px;
    }
    .breadcum{
        margin-bottom: 28px;
        @include respond-below(md){
            margin-bottom: 45px;
        }
    }
    .search-result-wrap{
        margin: auto;
        max-width: 958px;
    }
    .search-box{
        padding-bottom: 32px;
        @include respond-below(md){
            padding-bottom: 20px;
        }
        form{
            position: relative;
        }
        input{
            height: 61px;
            &[type="text"]{
                border-color:rgba(48,63,84,.25);
                font-size: 25px;
                padding: 10px 20px;
            }
            &.search-submit{
                position: absolute;
                top: 0;
                right: 0;
                width: 71px;
                height: 100%;
                background-image: url("../images/bue-search-icon.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 23px;
                background-color: transparent;
                padding: 10px;
            }
            @include placeholder {
                text-transform: inherit;
                line-height: 35px;
            }
        }
    }
    .search-listing{
        .search-count{
            color: $grey;
            padding-bottom: rem(10px);
        }
        .search-title{
            margin-bottom: rem(17px);
            @include respond-below(md){
                font-size: rem(20px);
                margin-bottom: rem(12px);
            }
        }
        .single-search-list{
            padding-bottom: 17px;
            padding-top: 17px;
            border-bottom: 1px solid rgba(114, 114, 114,.3);
            &:first-child{
                padding-top: 0;
            }
            .single-search-wrap{
                display: flex;
                @include respond-below(lg){
                    flex-wrap: wrap;
                    flex-direction: column-reverse;
                }
                .left-col{
                    width: 100%;
                    .small-label{
                        margin-bottom: 11px;
                        display: block;
                        color: #44BCED;
                    }
                    h4{
                        line-height: 1.16;
                        a{
                            text-decoration: none;
                            color: #1C3270;
                            &:hover{
                                color: $secondary-100;
                            }
                        }
                        @include respond-below(md){
                            font-size: rem(25px);
                        }
                    }
                    p{
                        line-height: 1.375;
                        @include respond-below(md){
                            line-height: rem(24px);
                        }
                    }
                }
                .right-col{
                    max-width: 264px;
                    width: 100%;
                    padding-top: 3px;
                    margin-left: 32px;
                    @include respond-below(lg){
                        margin-left: 0;
                        padding-top: 0;
                        padding-bottom: rem(25px);
                    }
                    @include respond-below(md){
                        padding-bottom: rem(20px);
                    }
                }
            }
        }
    }

}