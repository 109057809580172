.registration-form{
    padding: 70px 0 115px;
    @include respond-below(lg){
        padding: 70px 0;
    }
    @include respond-below(md){
        padding: 55px 0 70px;
    }
    .step-info-form{
        display: none;
        &.active{
            display: block;
        }
        // &:last-of-type{
            
        //             .submit-btn{
        //                 input{
        //                     // pointer-events: none;
        //                 }
        //             }
            
        // }
    }
    .registration-form-wrap{
        display: flex;
        flex-wrap: wrap;
        .step-wrap{
            @include respond-below(md){
                width: 100%;
            }
        }
        .form-steps{
            width: 250px;
            padding: 80px 15px 5px 29px;
            position: relative;
            @include respond-below(lg){
                width: 210px;
                padding-left: 0;
            }
            @include respond-below(md){
                width: 100%;
                padding: 0 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            &:before{
                content: '';
                position: absolute;
                left: 70px;
                top: 0;
                width: 2px;
                height: 100%;
                background-image: linear-gradient(to bottom, rgba($grey,.51) 20%, rgba(255, 255, 255, 0) 0%);
                background-position: left;
                background-size: 2px 9px;
                background-repeat: repeat-y;
                z-index: -1;
                @include respond-below(lg){
                    left: 42px;
                }
                @include respond-below(md){
                    left: 0;
                    top:11px;
                    width: calc(100% - 50px);
                    height: 2px;
                    background-position: top;
                    background-repeat: repeat-x;
                    background-image: linear-gradient(to left, rgba($grey,.51) 20%, rgba(255, 255, 255, 0) 0%);
                    background-size: 9px 2px;
                    right: 0;
                    margin: auto;
                }
            }
            
            .step-nav{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-bottom: 72px;
                background: #fff;
                position: relative;
                @include respond-below(md){
                    display: block;
                    margin-bottom: 16px;
                    background: transparent;
                }
                .step{
                    width: 86px;
                    height: 86px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 0 3px 6px rgba($black,.16);
                    border-radius: 50%;
                    margin-right: 20px;
                    color: $secondary-100;
                    font-size: 10px;
                    font-weight: bold;
                    letter-spacing: 0.4px;
                    @include respond-below(lg){
                        margin-right: 15px;
                    }
                    @include respond-below(md){
                        width: 25px;
                        height: 25px;
                        font-size: 0 !important;
                        letter-spacing: 0px;
                        margin-right: 0;
                        margin: auto;
                        margin-bottom: 2px;
                        background: $white;
                        span{
                            font-size: 14px;
                        }
                    }
                }
                .step-name{
                    font-size: 16px;
                    line-height: 21px;
                    font-weight: 500;
                    text-transform: uppercase;
                    letter-spacing: 0.7px;
                    color: rgba(62, 80, 102,.4);
                    @include respond-below(md){
                        font-size: 10px;
                        letter-spacing: 0.5px;
                    }
                }
                &.active{
                     .step{
                         font-size: 14px;
                         letter-spacing: 0.5px;
                         background: $blue;
                         box-shadow: none;
                         color: $white;
                         text-transform: uppercase;
                     }
                     .step-name{
                         color: #003057;
                     }
                 }
            }
        }
        .form-block{
            width: calc(100% - 250px);
            max-width: 666px;
            padding-left: 48px;
            padding-top: 2px;
            @include respond-below(lg){
                width: calc(100% - 210px);
                padding-left: 15px;
            }
            @include respond-below(md){
                width: 100%;
                padding-left: 0;
            }
            
            .form-title{
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                padding-right: 7px;
                padding-bottom: 22px;
                @include respond-below(md){
                    align-items: center;
                    padding-right: 3px;
                    padding-bottom: 14px;
                }
                h4{
                    margin-bottom: 0;
                    color: $blue;
                    @include respond-below(md){
                        font-size: 24px;
                    }
                }
                .step-count{
                    color: $blue;
                }
            }
        }
    }
}
.form-section{
    .form-wrap{
        margin: 0 -8px;
    }
    .form-group{
        width: 100%;
        padding: 0 8px;
        @include respond-below(md){
            margin-bottom: 11px;
        }
        &.half-col{
            width: 50%;
            @include respond-below(md){
                width: 100%;
            }
        }
        label{
            font-size: 12px;
            font-weight: 500;
            color: $orange;
        }
        .error-message{
            color: #f95e3d;
            font-size: 8px;
            text-align: right;
            display: block;
            font-weight: 600;
        }
        .label-title{
            font-size: 10px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            color: #183074;
            font-weight: 500;
        }
        .custom-check{
            input{
                opacity: 0;
                position: absolute;
                visibility: hidden;
                &:checked{
                    + label{
                        &:after {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
            label{
                padding-left: 26px;
                position: relative;
                color: #183074;
                cursor: pointer;
                &:before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 16px;
                    height: 16px;
                    border:1px solid rgba(62, 80, 101,.2)
                }
                &:after{
                    content: '';
                    position: absolute;
                    left: 4px;
                    top: 4px;
                    width: 9px;
                    height: 5px;
                    border:1px solid $blue;
                    border-top: 0;
                    border-right: 0;
                    transform: rotate(-45deg);
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }
    .submit-btn{
        text-align: right;
        width: 100%;
        padding-right: 30px;
        @include respond-below(md){
            padding: 0 8px;
            margin-top: 13px;
            input{
                width: 100%;
            }
        }
    }
}

.contact-form{
    padding-bottom:170px ;
    position: relative;
    @include respond-below(lg){
        padding-bottom: 100px;
    }
    @include respond-below(md){
        padding-bottom: 65px;
    }
    .circle-image{
        max-width: 741px;
        position: absolute;
        top: 80px;
        left: -111px;
        z-index: -1;
        @include respond-below(md){
            display: none;
        }
    }
    .contact-form-wrap{
        max-width: 562px;
        margin-left: auto;
        padding: 54px 44px 35px;
        margin-top: -280px;
        background: #fff;
        z-index: 1;
        position: relative;
        box-shadow: 0 3px 20px rgba($black, .16);
        @include respond-below(lg){
            margin-top: -30px;
            max-width: 100%;
        }
        @include respond-below(md){
            padding: 24px;
            margin-top: -63px;
        }
    }
    .form-wrap{
        position: relative;
        .submit-btn{
            position: absolute;
            bottom: 30px;
            right: 0;
            width: 50%;
            @include respond-below(md){
                position: static;
                width: 100%;
                input{
                    width: 100%;
                    
                }
            }
        }
    }
    .form-group{
        input{
            border-color: #CCCCCC;
        }
        .dropdown{
            display: flex;
            flex-wrap: wrap;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 4px 8px;
            height: 40px;
            background: url("../images/select-down-arrow2.svg");
            background-size: rem(27px) rem(15px);
            background-position: right 57%;
            background-repeat: no-repeat;
            label{
                font-size: 10px;
                line-height: 14px;
                margin-bottom: 3px;
                color: #707070;
                
            }
            select{
                border: none;
                height: auto;
                padding: 0;
                color: #cfd1d5;
                background: none;
            }
        }
    }
}
.map-section{
    @include respond-below(md){
        padding-bottom: 70px;
        overflow:hidden ;
        position: relative;
    }
    .circle-image{
        position: absolute;
        bottom: -420px;
        right: 87px;
        width: 740px;
    }
    .section-heading{
        max-width: 800px;
        margin: auto;
        margin-bottom: 45px;
    }
    .map-block{
        @include respond-below(md){
            padding: 0 35px;
        }
    }
.simple-map{
    padding-bottom: 37%;
    min-height: 483px;
    .gm-style-iw.gm-style-iw-c{
        max-width: 265px !important;
    }
    .location-info{
        max-width: 200px;
        padding: 24px 12px 30px;
        h4{
            font-size: 25px;
            line-height: 25px;
            margin-bottom: 6px;
            color: #807DB5;
        }
        p{
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 12px;
        }
    }
}
}