.wrapper {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: space-between;
	width: 100%;
	min-height: 100%;	
}

.main-container {
	width: 100%;
}

.slick-dots {
	li {
		padding: 0 rem(5px);
	}
}
