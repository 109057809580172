.partners-promo{
    padding: 73px 0;
    @include respond-below(md){
        padding: 30px 0;
    }
    .partners-content{
        margin: auto;
        max-width: 761px;
        @include respond-below(md){
            text-align: left;
            h2{
                margin-bottom: 8px;
            }
        }
    }
    .partners-logo{
        padding-top: 55px;
        padding-bottom: 11px;
        @include respond-below(xl){
            padding-bottom: 0;
        }
        @include respond-below(lg){
            padding-top: 40px;
        }
        @include respond-below(md){
            padding-bottom: 10px;
        }
        .partner-logo-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            .single-logo{
                width: 20%;
                text-align: center;
                padding: 0 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 30px;
                @include respond-below(md){
                    width: 50%;
                }
            }
        }
    }
}