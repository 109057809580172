.two-column-tabbed-promo{
    padding: 114px 0;
    overflow: hidden;
    @include respond-below(xl){
        padding: 80px 0;
    }
    @include respond-below(md){
        padding: 65px 0 58px;
    }
    .heading-title{
        padding-bottom: 11px;
        @include respond-below(md){
            padding-bottom: 5px;
        }
        h2{
            max-width: 50%;
            line-height: 1.25;
            @include respond-below(md){
                max-width: 100%;
                font-size: 29px;
            }
        }
    }
    .tabbed-promo-wrap{
        position: relative;
        .circle-image{
            position: absolute;
            top: -290px;
            right: -340px;
            max-width: 741px;
            // img{
            //     filter: grayscale(1);
            // }
            @include respond-below(md){
                display: none;
            }
        }
        .tab-list{
            width: calc(50% - 15px);
            margin-bottom: -10px;
            position: relative;
            @include respond-below(md){
                width: 100%;
                margin-bottom: 0;
            }
            .category-dropdown{
                @include respond-above(md){
                    display: none;
                }
                @include respond-below(md){
                    padding: 20px;
                    font-size: 18px;
                    border: 1px solid #F95E3D;
                    line-height: 22px;
                    font-weight: 500;
                    color: #F95E3D;
                    background: #ffffff;
                    position: relative;
                    width: 100%;
                    display: block;
                    &.active{
                        border-bottom: 0;
                    }
                    &:after{
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 26px;
                        width: 12px;
                        height: 12px;
                        border: 3px solid #F95E3D;
                        border-left: none;
                        border-bottom: none;
                        transform: translateY(-50%) rotate(135deg);
                        transition: all 0.5s;
                    }
                }
            }
            ul{
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                @include respond-below(md){
                    display: none;
                    border: 1px solid #F95E3D;
                    border-top: 0;
                    background: #ffffff;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                    width: 100%;
                    z-index: 2;
                }
                li{
                    font-size: 16px;
                    padding: 11px 16px 10px;
                    margin-right: 10px;
                    border-radius: 22px;
                    transition: all .5s;
                    line-height: 1.22;
                    cursor: pointer;
                    @include respond-below(lg){ 
                        margin-right: 5px;
                        padding: 11px 14px 10px;
                    }
                    @include respond-below(md){
                        width: 100%;
                        padding: 5px 20px 15px;
                    }
                    &.active{
                        font-size: 18px;
                        background: $orange;
                        color: $white;
                        @include respond-below(lg){
                            font-size: 17px;
                        }
                        @include respond-below(md){
                            background: transparent;
                            font-size: 16px;
                            color: #F95E3D;
                        }
                    }
                }
            }
        }
        .tabbed-block-single{
            display: none;
            @include respond-below(md){
                padding-top: 23px;
            }
            .tabbed-block-inner{
                @include respond-below(md){
                    flex-direction: column-reverse;
                }
            }
            .tabbed-desc{
                padding: 48px 15px 0;
                @include respond-below(md){
                    padding: 32px 15px 0;
                }
                .heading-block{
                    padding-right: 20px;
                    padding-bottom: 35px;
                    @include respond-below(xl){ 
                        padding-right: 0;
                        padding-bottom: 25px;
                    }
                    @include respond-below(md){
                        padding-bottom: 17px;
                    }
                    h3{
                        margin-bottom: 10px;
                        @include respond-below(md){
                            font-size: 24px;
                            margin-bottom: 7px;
                        }
                    }
                }
                .tabbed-icon-block{
                    padding-right: 60px;
                    @include respond-below(xl){
                        padding-right: 0;
                    }
                    .single-icon-block {
                        display: flex;
                        flex-wrap: wrap;
                        padding-bottom: 37px;
                        @include respond-below(lg){
                            padding-bottom: 25px;
                        }
                        @include respond-below(md){
                            padding-bottom: 37px;
                        }
                        .icon{
                            max-width: 70px;
                            width: 100%;
                            text-align: right;
                            @include respond-below(lg){
                                max-width: 55px;
                            }
                            @include respond-below(md){
                                max-width: 70px;
                            }
                        }
                        .icon-content{
                            width: calc(100% - 70px);
                            padding-left: 22px;
                            padding-top: 5px;
                            h6{
                                font-size: 16px;
                                line-height: 1.3;
                                margin-bottom: 2px;
                                color: $secondary-100;
                            }
                        }
                    }
                    a.btn{
                        margin-top: 15px;
                        @include respond-below(md){
                            margin-top: 0;
                            width: 100%;
                        }
                    }
                }
            }
            .tabbed-img{
                .tabbed-img-wrap{
                    padding-left: 5px;
                    figure{
                        position: relative;
                        padding-bottom: 67%;
                        overflow:hidden;
                        border-radius: 7px;
                        &:after{
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(180deg, rgba(114, 114, 114,0) 0%, #0E2D57 100%)
                        }
                        @include imgFit;
                    }
                }
            }
        }
    }
}