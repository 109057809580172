// variable and mixin
// @import 'abstracts/core' ;

.hero-section{
    background: rgba(5,36,70,1);
    background: linear-gradient(270deg, rgba(5,36,70,1) 0%, rgba(18,71,142,1) 100%);
    position: relative;
    min-height: 808px;
    padding: 160px 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    @include respond-below(xl){
        min-height: 768px ;
    }
    @include respond-below(md){
        align-items: flex-start;
        min-height: 665px;
    }
    &::before{
        content: '';
        position: absolute;
        left: 0;
        top:0;
        width: 100%;
        height: 100%;
        background: image('hero-pattern.png') no-repeat center center;
        background-size: cover;
        mix-blend-mode: multiply;
        z-index: 0;
    }
    &:after{
        content: '';
        position: absolute;
        top: -512px;
        right: 80px;
        width: 911px;
        height: 911px;
        background: image('banner-dott-image.png');
        opacity: 0.25;
        z-index: 1;
        @include respond-below(md){
            top: -104px;
            right: -272px;
            width: 615px;
            height: 615px;
            background-size: cover;
        }
    }
    .banner-pattern{
        position: absolute;
        top: -91px;
        right: -470px;
        max-width: 1069px;
        mix-blend-mode: overlay;
        @include respond-below(xl){
            max-width: 900px;
            top: -30px;
            right: -450px;
        }
        @include respond-below(md){
            max-width: 470px;
            top: auto;
            right: -262px;
            bottom: -134px;
        }
    }
    .hero-section-wrap{
        z-index: 1;
    }
    .main-slider{
        z-index: 2;
        .item{
            max-width: 512px;
            @include respond-below(md){
                padding-right: 60px;
            }
            h1{
                color: $white;
                margin-bottom: 0;
            }
            p{
                color: $white;
                font-size: 20px;
                line-height: 1.5;
                margin-bottom: 15px;
                @include respond-below(md){
                    font-size: 16px;
                    margin-bottom: 11px;
                }
            }
        }
    }
    .progressBarslider{
        position: absolute;
        bottom: 0;
        padding-bottom: 80px;
        display: flex;
        flex-wrap: wrap;
        @include respond-below(md){
            left: 0;
            max-width: 100%;
            padding: 0 15px 33px;
            .slick-list{
                order: 1;
                padding-bottom: 10px;
            }
            .slick-prev{
                order: 2;
                position: static;
                margin-top: 8px;
            }
            .slick-dots{
                order: 3;
                margin: 0;
                width: auto;
            }
            .slick-next{
                order: 4;
                position: static;
                margin-top: 8px;
            }
        }
        .item{
            margin-right: 65px;
            position: relative;

            color: $white;
            font-size: 17px;
            line-height: 21px;
            font-weight: 500;
            padding-top: 40px;
            display: block;
            position: relative;
            cursor: pointer;
            @include respond-below(lg){
                margin-right: 30px;
                padding-top: 25px;
            }
            @include respond-below(md){
                padding-top: 15px;
                margin-right: 0;
            }
                
            span{
                opacity: 0;
                &:before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 0;
                    height: 4px;
                    background: rgba(255,255,255,.12);
                }
                &:after{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 0;
                    height: 4px;
                    background: #807DB5;
                    transition: all 3s;
                }
            }
            &.active, &.slick-current {
                span{
                    opacity: 1;
                    &:before{
                        width: 100%;
                        @include respond-below(md){
                            width: 107px;
                        }
                    }
                    &:after{
                        width: 100%;
                        @include respond-below(md){
                            width: 107px;
                        }
                    }
                }
            }
        }
        .slick-slide {
            &.slick-current{
                .item{
                    
                }
            }   
        }
    }
}