.video-promo{
    padding: 62px 0 120px;
    background: linear-gradient(270deg, #1160B0 0%, #183074 100%);
    position: relative;

    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../images/video-comp-bg.png);
        bottom: 0;
        right: 0;
        mix-blend-mode: multiply;
    }
    @include respond-below(lg){
        padding: 60px 0;
    }
    @include respond-below(md){
        padding: 45px 0;
    }
    .section-title{
        padding-bottom: 40px;
        @include respond-below(lg){
            padding-bottom: 20px;
        }
        @include respond-below(md){
            padding-bottom: 4px;
        }
        h2{
            color: $white;
        }
    }
    .promo-nav{
        width: 41.3%;
        @include respond-below(md){
            width: 100%;
            position: relative;
        }
        .promo-dropdown{
            padding: 20px;
            font-size: 18px;
            border: 1px solid #67BDB7;
            line-height: 22px;
            font-weight: 500;
            position: relative;
            width: 100%;
            display: none;
            color: $white;
            @include respond-below(md){
                display: block;
            }
            h5{
                margin: 0;
                color: $white;
            }
            p{
                display: none !important;
            }
            &:after{
                content: "";
                position: absolute;
                top: 50%;
                right: 26px;
                width: 12px;
                height: 12px;
                border: 3px solid #67BDB7;
                border-left: none;
                border-bottom: none;
                transform: translateY(-50%) rotate(135deg);
                transition: all 0.5s;
            }
        }
        .promo-nav-wrap{
            padding: 16px 100px 0 0;
            @include respond-below(xl){
                padding: 0;
            }
            @include respond-below(md){
                display: none;
                // position: absolute;
                top: 100%;
                left: 0px;
                // width: calc(100% - 30px);
                z-index: 1;
                right: 0px;
                margin: auto;
                border: 1px solid #67BDB7;
                // background: rgb(255, 255, 255);
            }
            .nav-item{
                padding-left: 20px;
                margin-bottom: 38px;
                cursor: pointer;
                position: relative;
                @include respond-below(lg){
                    margin-bottom: 25px;
                }
                @include respond-below(md){
                    margin: 10px 0;
                }
                &:before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 2px;
                    height: 100%;
                    background: rgba($white, .3);
                    @include respond-below(md){
                        display: none;
                    }
                }
                &:after{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 2px;
                    height: 0;
                    background: #2DAEA8;
                    transition: all 2s;
                    @include respond-below(md){
                        display: none;
                    }
                }
                h5{
                    margin-bottom: 20px;
                    line-height: 20px;
                    padding-top: 3px;
                    transition: all .5s;
                    color: #fff;
                    @include respond-below(md){
                        color: #67BDB7;
                        margin-bottom: 0;
                    }
                }
                p{
                    font-size: 18px;
                    line-height: 22px;
                    color: $white;
                    display: none;
                    @include respond-below(lg){
                        font-size: 16px;    
                    }
                    @include respond-below(md){
                        display: none !important;
                    }
                }
                &.active{
                    h5{
                        font-weight: bold;
                        color: #2DAEA8;
                    }
                    &:after{
                        height: 100%;
                    }
                }
            }
        }
        
    }
    .tab-content{
        width: 58.7%;
        @include respond-below(md){
            width: 100%;
            padding-top: 20px;
        }
        .tab-video{
            // position: relative;
            .mob-text{
                @include respond-below(md){
                    padding-bottom: 20px;
                }
                p{
                    color: $white;
                }
            }
            a{
                display: block;
                position: relative;
            }
            figure{
                position: relative;
                padding-bottom: 61.3%;
                @include respond-below(md){
                    border-radius: 8px;
                    overflow: hidden;
                }
                @include imgFit;
            }
            .play-ico{
                max-width: 90px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                @include respond-below(lg){
                    max-width: 65px;
                }
                @include respond-below(md){
                    max-width: 48px;
                }
            }
        }
    }
}