// Take away comment if want to separate file and remove _ before file name
/* Slick Slider */
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	--webkit-touch-callout: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;

	.slick-list {
		position: relative;
		overflow: hidden;
		display: block;
		margin: 0;
		padding: 0;

		&:focus {
			outline: none;
		}

		&.dragging {
			cursor: pointer;
			cursor: hand;
		}
	}

	.slick-track,
	.slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		left: 0;
		top: 0;
		display: flex;
		margin-left: auto;
		margin-right: auto;

		.slick-loading & {
			visibility: hidden;
		}
	}

	@at-root .slick-slide {
		height: 100%;
		min-height: 1px;

		[dir="rtl"] & {
			float: right;
		}

		img {
			display: block;
		}

		&.slick-loading img {
			display: none;
		}

		display: none;

		&.dragging img {
			pointer-events: none;
		}

		.slick-initialized & {
			display: block;
		}

		.slick-loading & {
			visibility: hidden;
		}

		.slick-vertical & {
			display: block;
			height: auto;
			border: 1px solid transparent;
		}
	}

	&.slick-dotted {
	}

	// dots navigation
	.slick-dots {
		position: relative;
		width: 100%;
		margin: rem(15px) 0;
		padding: 0;
		text-align: center;

		li {
			display: inline-block;
			vertical-align: middle;
			list-style: none;
			line-height: 0;
			padding-left: 5px;
			padding-right: 5px;

			button {
				width: 13px;
				height: 13px;
				padding: 0;
				border: none;
				border-radius: 100%;
				font-size: 0;
				background-color: #E0EDF9;
				transition: background-color ease 0.3s, opacity ease 0.3s;
				outline: none;
				&:focus,
				&:hover {
					outline: none;
					cursor: pointer;
					background-color: $orange;
					opacity: 0.8;
				}
			}

			&.slick-active button {
				background-color: $orange;
			}
		}
	}
}

//  arrows
.slick-arrow {
	--sArrowWidth: 20px;
	position: absolute;
	top: calc(50% - (var(--sArrowWidth) / 2));
	padding: 0;
	width: 12px;
	height: 12px;
	font-size: 0;
	background-color: transparent;
	box-shadow: none;
	border: 1px solid #E0EDF9;
	border-top: 0;
	border-right: 0;
	transition: border-color ease 0.3s;
	z-index: 9;

	&:focus,
	&:hover {
		outline: none;
		cursor: pointer;
		border-color: $orange;
	}
	&.slick-prev {
		left: 0;
		border-top: 0;
		border-right: 0;
		transform: rotate(45deg);
	}

	&.slick-next {
		right: 0;
		transform: rotate(-135deg);
	}
	&.slick-hidden {
		display: none;
	}
}
