.staggered-image-5050{
    padding: 63px 0 73px;
    @include respond-below(md){
        padding: 40px 0;
    }
    .staggered-wrap{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        margin: 0 -15px;
        .left-col{
            width: 50%;
            padding: 0 15px;
            @include respond-below(md){
                width: 100%;
                padding-bottom: 25px;
            }
            .image-blk{
                display: flex;
                flex-wrap: wrap;
                margin: 0 -16px;
                @include respond-below(xl){
                    margin: 0 -10px;
                }
            }
            .single-image{
                padding: 0 16px;
                width: 50%;
                @include respond-below(xl){
                    padding: 0 10px;
                }
                
                figure{
                    position: relative;
                    padding-bottom: 100%;
                    border-radius: 8px;
                    overflow: hidden;
                    @include imgFit;
                    &::before{
                        content:'';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background: -webkit-linear-gradient(top,  rgba(114,114,114,0) 0%,rgba(14,45,87,1) 100%);
                        background: linear-gradient(to bottom,  rgba(114,114,114,0) 0%,rgba(14,45,87,1) 100%);
                        opacity: 0.69;
                        z-index: 1;
                    }
                }
                &:last-child{
                    margin-top: 22.3%;
                }
            }
        }
        .right-col{
            width: 50%;
            padding: 0 15px;
            @include respond-below(md){
                width: 100%;
            }
            h2{
                margin-bottom: 10px;
            }
            p{
                max-width: 463px;
                line-height: 22px;
                color: #707070;
                @include respond-below(md){
                    line-height: 24px;
                    max-width: 100%;
                }
            }
        }
    }
}