.awards-promo{
    padding: 72px 0 110px;
    @include respond-below(xl){
        padding: 70px 0 80px;
    }
    .section-title{
        max-width: 770px;
        margin: auto;
        padding-bottom: 38px;
        h2{
            margin-bottom: 25px;
        }
        p{
            font-size: 20px;
            color: #727272;    
        }
    }
    .list-years{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-bottom: 15px;
        .award-dropdown{
            padding: 20px;
            font-size: 18px;
            border: 1px solid #F95E3D;
            line-height: 22px;
            font-weight: 500;
            color: #F95E3D;
            background: #ffffff;
            position: relative;
            width: 100%;
            display: block;
            @include respond-above(md){
                display: none;
            }
            a{
                text-decoration: none;
            }
            &:after{
                content: "";
                position: absolute;
                top: 50%;
                right: 26px;
                width: 12px;
                height: 12px;
                border: 3px solid #F95E3D;
                border-left: none;
                border-bottom: none;
                transform: translateY(-50%) rotate(135deg);
                transition: all 0.5s;
            }

        }
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            padding: 0 15px;
            border-bottom: 1px solid $secondary-100;
            @include respond-above(md){
                display: flex !important;
                height: auto !important;
                padding: 0 15px !important;
            }
            @include respond-below(md){
                border-bottom: none;
                padding: 10px 20px;
                width: 100%;
                border:1px solid #F95E3D;
                display: none;
            }
            li{
                padding: 0 26px;
                @include respond-below(md){
                    display: block;
                    width: 100%;
                    text-align: left;
                    padding: 10px 0;
                }
                a{
                    font-size: 14px;
                    line-height: 19px;
                    min-width: 47px;
                    text-align: center;
                    text-decoration: none;
                    font-weight: bold;
                    padding-bottom: 7px;
                    display: block;
                    color: rgba(112, 112, 112,0.6);
                    position: relative;
                    @include respond-below(md){
                        text-align: left;
                        font-size: 18px;
                        font-weight: 500;
                    }
                    &:after{
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 0;
                        height: 6px;
                        background: $secondary-100;
                        transition: all .5s;
                        @include respond-below(md){
                            display: none;
                        }
                    }
                    &.active{
                        color: $secondary-100;
                        &:after{
                            width: 100%;
                        }
                    }
                }
            }
        }
        .slider-title{
            width: 100%;
            text-align: center;
            padding-top: 38px;
        }
    }
    #award-slide-listing{
        @include respond-below(md){
            display: block !important;
        }
    }
    .award-slider{
        @include respond-below(md){
            display: none;
        }
        .slick-dots{
            position: absolute;
            width: 0;
            height: 0;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }
        .slick-list{
            margin: 0 -16px;
            // @include respond-below(md){
            //     overflow:visible ;
            //     .slick-track{
            //         width: 100% !important;
            //         display: block;
            //     }
            // }
            .slick-slide{
                padding: 0 16px;
                // @include respond-below(md){
                //     margin: auto;
                //     width: 100% !important;
                // }
                > div{
                    height: 100%;
                    // @include respond-below(md){
                    //     text-align: center;
                    // }
                }
                
            }
        }
    }
    .award-item{
        text-align: center;
        background: #F4F4F4;
        padding: 25px;
        height: 100%;
        @include respond-below(md){
            max-width: 265px;
            margin: auto;
            margin-bottom: 24px;
        }
        .award-logo{
            max-width: 186px;
            height: 124px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            margin-bottom: 14px;
        }
        h6{
            font-size: 16px;
            line-height: 1.1;
            color: #2699FB;
            font-weight: bold;
            margin-bottom: 0;
        }
    }
    .pagination{
        padding: 50px 0 0 0;
        display: block;
        @include respond-below(md){
            display: none;
        }
        .paginathin-wrap{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0;
            padding: 0;
            list-style: none;
            .arrow{
                list-style: none;
                margin: 0 7px;
                padding: 0;
                color: $blue;
                width: auto;
                transform: inherit;
                position: inherit;
                top: inherit;
                height: auto;
                border: none;
                a{
                    text-decoration: none;
                    &:hover{
                        color: $orange;
                    }
                }
                &.prev-nav{
                    margin-right: 37px;
                    font-weight: 500;
                    font-size: 21px;
                    a{
                        padding-left: 15px;
                        color: $blue;
                        position: relative;
                        &:before{
                            content: '';
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%) rotate(-45deg);
                            width: 7px;
                            height: 7px;
                            border: 2px solid $blue;
                            border-right: 0;
                            border-bottom: 0;
                            left: 0;
                            transition: all .5s;
                        }
                        &:hover{
                            color: $orange;
                            &:before{
                                border-color: $orange;
                            }
                        }
                    }
                }
                &.next-nav{
                    margin-left: 37px;
                    font-weight: 500;
                    font-size: 21px;
                    a{
                        padding-right: 15px;
                        color: $blue;
                        position: relative;
                        &:before{
                            content: '';
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%) rotate(135deg);
                            width: 7px;
                            height: 7px;
                            border: 2px solid $blue;
                            border-right: 0;
                            border-bottom: 0;
                            right: 0;
                            transition: all .5s;
                        }
                        &:hover{
                            color: $orange;
                            &:before{
                                border-color: $orange;
                            }
                        }
                    }
                }
                &:nth-child(4){
                    font-weight: 500;
                }
                &.slick-hidden{
                    + {
                        .slides-numbers{
                            display: none;
                        }
                    }
                }
            }
            .slides-numbers{
                span{
                    margin: 0 10px;
                    &.total{
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.single-row-awards{
    padding: 72px 0 110px;
    @include respond-below(xl){
        padding: 70px 0 80px;
    }
    .section-title{
        max-width: 770px;
        margin: auto;
        padding-bottom: 38px;
        h2{
            margin-bottom: 25px;
        }
        p{
            font-size: 20px;
            color: #727272;    
        }
    }
    .award-slider{
        display: flex;
        flex-wrap: wrap;
        @include respond-below(sm){
            flex-direction: column;
        }
        .award-item{
            width: calc(25% - 24px);
            margin-right: 32px;
            text-align: center;
            background: #F4F4F4;
            padding: 25px;
            @include respond-below(lg){
                width: calc(50% - 16px);
                margin-bottom: 30px;
                &:nth-child(even){
                    margin-right: 0;
                }
            }
            @include respond-below(md){
                max-width: 100%;
            }
            @include respond-below(sm){
                width: 100%;
                max-width: 265px;
                margin:auto !important;
                margin-bottom: 24px !important;
            }
            &:last-child{
                margin-right: 0;
            }
            .award-logo{
                max-width: 186px;
                height: 124px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto;
                margin-bottom: 14px;
            }
            h6{
                font-size: 16px;
                line-height: 1.1;
                color: #2699FB;
                font-weight: bold;
                margin-bottom: 0;
            }
            
        }
    }
}


