.two-column-text-promo-with-cta{
    padding-bottom: 120px;
    padding-top: 8px;
    @include respond-below(md){
        padding-bottom: 50px;
        padding-top: 60px;
    }
    + .two-column-text-promo-with-cta{
        padding-top: 0px;
        @include respond-below(md){
            padding-top: 0;
        }
    }
    .two-column-wrap{
        .section-title{
            h2{
                margin-bottom: 35px;
                color: #807DB5;
                @include respond-below(md){
                    margin-bottom: 25px;
                }
            }
        }
        .two-column-content{
            &:not(:last-child){
                margin-bottom: 80px;
                @include respond-below(md){
                    margin-bottom: 0;
                }
            }
            .row{
                // margin: 0 -70px;
                .half-col{
                    // padding: 0 70px;
                    @include respond-below(md){
                        padding-bottom: 22px;
                    }
                    .inner-col{
                        max-width: 482px;
                    }
                    h4{
                        line-height: 1.16;
                        margin-bottom: 32px;
                        color: #1c3270;
                        @include respond-below(md){
                            font-size: 20px;
                            line-height: 1.45;
                            margin-bottom: 17px;
                        }
                    }
                    h6{
                        font-size: 16px;
                        line-height: 26px;
                        color: $secondary-100;
                        margin-bottom: 0;
                    }
                    p{
                        margin-bottom: 17px;
                        @include respond-below(md){
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
    .column-btn{
        a.btn{
            @include respond-below(md){
                width: 100%;
            }
        }
    }
}