.accordion{
    padding: 127px 0 100px;
    @include respond-below(xl){
        padding: 80px 0;
    }
    @include respond-below(lg){
        padding: 60px 0;
    }
    @include respond-below(md){
        padding: 72px 0 57px;
    }
    .accordion-wrap{
        max-width: 958px;
        margin: 0 auto;
        .section-heading{
            h2{
                margin-bottom: 27px;
                @include respond-below(md){
                    margin-bottom: 15px;
                }
            }
        }
        .single-accordion{
            padding: 22px 25px 22px 33px;
            box-shadow: 0 3px 16px rgb(0 0 0 / 16%);
            margin-bottom: 15px;
            border-radius: 5px;
            @include respond-below(md){
                padding: 22px 30px 22px 32px;
            }
            .accordion-title{
                padding: 5px 0;
                cursor: pointer;
                position: relative;
                padding-right: 35px;
                h5{
                    margin-bottom: 0;
                    color: #807DB5;
                }
                .accordion-toggle{
                    width: 31px;
                    height: 31px;
                    background: #2DAEA8;
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    &:before, &:after{
                        content: '';
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background: $white;
                        top: 50%;
                    }
                    &:before{
                        width: 3px;
                        height: 17px;
                    }
                    &:after{
                        width: 17px;
                        height: 3px;
                    }
                }
                
            }
            .accordion-content{
                padding-top: 15px;
                padding-bottom: 3px;
                @include respond-below(md){
                    padding-top: 5px;
                    padding-bottom: 14px;
                }
            }
            &.active{
                .accordion-title{
                    .accordion-toggle{
                        &:before{
                            display: none;
                        }
                    }
                } 
            }
        }
    }
}