.two-column-cta{
    padding: 85px 0;
    @include respond-below(lg){
        padding: 60px 0;
    }
    @include respond-below(md){
        padding: 35px 0;
    }
    .single-column-cta{
        @include respond-below(md){
            margin-bottom: 30px;
        }
        .single-column-wrap{
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 46px 30px 30px;
            justify-content: center;
            background: #1468A5;
            background: linear-gradient(0deg, #44BCED 0%, #1160B0 100%);
            align-content: center;
            text-align: center;
            @include respond-below(md){
                padding: 46px 30px 50px;
            }
            h3{
                font-size: 30px;
                letter-spacing: 0;
                color: #fff;
                margin-bottom: 12px;
                @include respond-below(lg){
                    font-size: 27px;
                }
                @include respond-below(md){
                    font-size: 24px;
                    line-height: 28px;
                    margin-bottom: 14px;
                }
            }
            p{
                font-size: 18px;
                line-height: 22px;
                color: #fff;
                margin-bottom: 30px;
                @include respond-below(lg){
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 20px;
                }
                @include respond-below(md){ 
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 20px;
                }
            }
            a{
                background: $secondary-100;
                &:hover{
                    background: #2a9690;
                }
            }
        }
        &:nth-child(even){
            .single-column-wrap{
                background: #1468A5;
                background: linear-gradient(0deg, #1160B0 0%, #183074 100%);
            }
        }
    }
}