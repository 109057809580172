.testimonial-section{
    padding: 90px 0;
    background: #1160B0;
    margin-bottom: 77px;
    background: linear-gradient(270deg, #1160B0 0%, #183074 100%);
    position: relative;
    @include respond-below(lg){
        padding: 60px 0;
        margin-bottom: 50px;
    }
    @include respond-below(md){
        padding: 52px 0 35px;
        margin-bottom: 10px;
    }
    &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(../images/testimonial-pattern.png) no-repeat center center;
        background-size: cover;
        mix-blend-mode: multiply;
        z-index: 0;
    }
    .testimonial-text{
        max-width: 960px;
        margin: auto;
        position: relative;
        .small-label{
            margin-bottom: 5px;
            @include respond-below(md){
                margin-bottom: 15px;
            }
        }
        p{
            font-size: 24px;
            line-height: 1.6;
            color: #fff;
            font-weight: 400;
            @include respond-below(md){
                font-size: 20px;
                line-height: 1.5;
                margin-bottom: 10px;
            }
        }
        .author-block{
            display: flex;
            align-items: center;
            padding-bottom: 8px;
            span{
                display: inline-block;
                color: #fff;
                &.author{
                    font-weight: bold;
                    color: #41C0B8;
                    margin-right: 35px;
                    @include respond-below(md){
                        font-size: 18px;
                    }
                }
            }
        }
    }
}