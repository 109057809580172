.filter{
    &-section{
        margin-top: -54px;
        z-index: 1;
        position: relative;
        @include respond-below(lg){
            margin-top: -64px;
        }
        @include respond-below(md){
            margin-top: 0;
        }
        .category-drop-down{
            margin-right: 32px;
            max-width: 219px;
            width: 100%;
            @include respond-below(xl){
                margin-right: 25px;
            }
            @include respond-below(lg){
                margin-right: 20px;
            }
            @include respond-below(md){
                max-width:100% ;
                margin-right: 0;
                margin-bottom: 16px;
            }
        }
        .search-form-wrapper{
            max-width: 405px;
            width: 100%;
            margin-right: 38px;
            @include respond-below(xl){
                margin-right: 25px;
                max-width: 366px;
            }
            @include respond-below(lg){
                margin-right: 20px;
                max-width: 276px;
            }
            @include respond-below(md){
                max-width:100% ;
                margin-right: 0;
            }
            .search-form{
                position: relative;
                .search-field{
                    height: 56px;
                    font-size: 18px;
                    padding: 0 56px 0 20px;
                    letter-spacing: 0;
                    color: rgb(62 80 102 / 75%);
                    border-color: rgba($body-color, .2);
                    border-radius: 5px;
                }
                .search-submit{
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 56px;
                    width: 56px;
                    min-width: auto;
                    font-size: 0;
                    padding: 0.63rem;
                    border: 2px solid transparent;
                    background-image: url(../images/bue-search-icon.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 18px;
                    background-color: transparent;
                }
            }
        }
        .reset-filter{
            @include respond-below(md){
                width: 100%;
                text-align: right;
                padding-top: 7px;
            }
            a{
                font-size: 16px;
                color: #44BCED;
                text-decoration: none;
                font-weight: 500;
                &:hover{
                    color: $orange;
                }
            }
        }
    }
    &-wrap{
        display: flex;
        flex-wrap: wrap;
        padding: 26px 35px;
        align-items: center;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 3px 16px rgba($black, .16);
        @include respond-below(lg){
            padding: 25px 25px;
        }
        @include respond-below(md){
            box-shadow: none;
            padding: 50px 0 23px;
        }
        h5{
            margin-right: 32px;
            margin-bottom: 0;
            color: $blue;
            @include respond-below(xl){
                margin-right: 25px;
            }
            @include respond-below(lg){
                width: 100%;
                margin: 0 0 20px 0;
            }
            @include respond-below(md){
                font-size: 20px;
                margin: 0 0 7px 0;
            }
        }
    }
}
.select2{
    .selection{
        .select2-selection--single{
            height: 56px;
            border-color: rgba($body-color, .2);
            .select2-selection__rendered{
                padding: 15px 22px;
                font-size: 18px;
                color: #3E5066;
                @include respond-below(lg){
                    padding: 15px 25px 15px 15px;
                    font-size: 16px;
                }
                @include respond-below(md){
                    padding: 14px 25px 14px 20px;
                    font-size: 18px;
                }
            }
            .select2-selection__arrow{
                top: 50%;
                transform: translateY(-50%);
                right: 16px;
                width: 18px;
                b{
                    border-width: 7px 7px 0 7px;
                    margin-left: -6px;
                    margin-top: -2px;
                    border-color: #183074 transparent transparent transparent;
                }
            }
        }
    }
    &.select2-container--open{
        .select2-selection--single{
            border-bottom: none;
            .select2-selection__arrow{
                b{
                    border-width: 0 7px 7px 7px !important;
                    border-color: transparent transparent #183074 transparent !important;
                }
            } 
        }
    }
}
.select2-container{
    .select2-search{
        display: none;
    }
    .select2-dropdown{
        border-color: rgba($body-color, .2);
        .select2-results__options{
            padding-bottom: 20px;
            li{
                padding: 4px 22px;
                font-size: 18px;
                background: transparent;
                &.select2-results__option--highlighted{
                    font-weight: 500;
                    color: #3E5066;
                    background: transparent;
                }
                &:hover{
                    font-weight: 500;
                    background: transparent;
                }
            }
        }
    }
}