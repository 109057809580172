.solution-promo{
    padding: 85px 0 74px;
    background: #44BCED;
    background: linear-gradient(270deg, #44BCED 0%, #1160B0 100%);
    overflow: hidden;
    @include respond-below(lg){
        padding: 80px 0;
    }
    @include respond-below(md){
        padding: 57px 0;
    }
    .solution-promo-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 -15px;
        .left-col{
            position: relative;
            width: 50%;
            padding: 0 15px;
            @include respond-below(lg){
                width: 100%;
                padding-bottom: 30px;
            }
            .circle-image{
                position: absolute;
                left: 30%;
                top: 50%;
                transform: translate(-50% , -50%) rotate(1080deg);
                width: 988px;
                height: 988px;
                z-index: 0;
                pointer-events: none;
                transition: all .8s;
                opacity: 0;
                @include respond-below(md){
                    max-width: 100%;
                    top: 0;
                    left: 0;
                    transform: none;
                }
            }
            h2{
                color: $white;
                @include respond-below(md){
                    margin-bottom: 7px;
                }
            }
            p{
                color: $white;
                font-size: 25px;
                line-height: rem(30px);
                @include respond-below(md){
                    font-size: 20px;
                }
            }
        }
        .right-col{
            width: 50%;
            padding: 0 15px;
            position: relative;
            @include respond-below(lg){
                width: 100%;
            }
            .see-all-link{
                @include respond-below(md){
                    padding-top: 40px;
                    text-align: center;
                }
            }
            .circle-arrow{
                bottom: 108px;
                left: 57.3%;
                position: absolute;
            }
            .right-col-wrap{
                display: flex;
                flex-wrap: wrap;
                margin: 0 -12px;
                position: relative;
                @include respond-below(md){
                    margin: 0;
                    padding: 0 27px;
                    justify-content: center;
                }
                .slick-list{
                    @include respond-below(md){
                        order: 1;
                        padding-bottom: 25px;
                    }
                }
                .slick-arrow{
                    position: inherit;
                }
                .slick-prev{
                    @include respond-below(md){
                        order: 2;
                    }
                }
                .slick-dots{
                    @include respond-below(md){
                        order: 3;
                        display: inline-flex;
                        width: auto;
                        margin: 0;
                    }
                }
                .slick-next{
                    @include respond-below(md){
                        order: 4;
                    }
                }
                .solution-block{
                    width: 50%;
                    padding: 0 12px;
                    margin-bottom: 30px;
                    @include respond-below(md){
                        margin-bottom: 0;
                    }
                    .solution-block-wrap{
                        background: #fff;
                        padding: 20px 20px 20px;
                        border-radius: 5px;
                        transition: all .5s;
                        @include respond-below(xl){
                            padding: 20px 15px;
                        }
                        @include respond-below(md){
                            padding: 20px;
                        }
                        .block-icon{
                            margin-bottom: 10px;
                            figure{
                                max-width: 85px;
                                height: 85px;
                                display: flex;
                                align-items: center;
                                @include respond-below(xl){
                                    max-width: 70px;
                                    height: 70px;
                                }
                                @include respond-below(md){
                                    max-width: 80px;
                                    height: 80px;
                                }
                            }
                        }
                        h4{
                            margin-bottom: 9px;
                            color: $secondary-100;
                            @include respond-below(md){
                                font-size: 25px;
                                margin-bottom: 5px;
                            }
                        }
                        p{
                            line-height: rem(22px);
                            margin-bottom: 18px;
                        }
                        a.btn-link{
                            color: $secondary-100;
                            &:after{
                                background: image('short-arrow.svg') no-repeat;
                                width: 6px;
                                height: 10px;
                            }
                        }
                    }
                    &:nth-child(even){
                        @include respond-above(md){
                            position: relative;
                            transform: translateY(50%);
                            margin-bottom: 0;
                        }
                        
                    }
                    &:hover{
                        .solution-block-wrap{
                            @include respond-above(md){
                                transform: translateY(-20px);
                            }
                        }
                    }
                }
            }
        }
    }
    &.animate{
        .solution-promo-wrap{
            .left-col{
                .circle-image{
                    transform: translate(-50%,-50%) rotate(0);
                    opacity: 1;
                }
            } 
        } 
    }
}
.circle-arrow{
    a{
        color: #fff;
        font-weight: bold;
        &:after{
            width: 35px;
            height: 35px;
            background: image('circle-arrow-white.svg') no-repeat;
            top: 0;
            transform: translateY(40%);
        }
    }
}
.desktop{
    @include respond-below(md){
        display: none;
    }
}
.mobile{
    @include respond-above(md){
        display: none;
    }
}