.text-only-5050{
    padding: 88px 0 52px;
    @include respond-below(lg){
        padding: 70px 0 35px;
    }
    @include respond-below(md){
        padding: 70px 0 25px;
    }
    .inner-block-5050{
        display: flex;
        flex-wrap: wrap;
        .left-col{
            width: 38%;
            @include respond-below(md){
                width: 100%;
            }
            h2{
                line-height: 1.25;
                @include respond-below(md){
                    margin-bottom: 4px;
                }
            }
        }
        .right-col{
            width: 62%;
            padding: 0 0px 0 56px;
            @include respond-below(md){
                width: 100%;
            }
            @include respond-below(xl){
                padding: 0 15px;
            }
            @include respond-below(md){
                padding: 0;
            }
            
        }
    }
}
.tick-listing{
    padding-top: 11px;
    @include respond-below(md){
        padding-top: 5px;
    }
    ul{
        padding: 0;
        margin: 0;
        list-style: none;
        li{
            padding: 0 0 14px 35px;
            position: relative;
            &:before{
                content: '';
                position: absolute;
                left: 0;
                top: 3px;
                width: 19px;
                height: 19px;
                background: image('tick-icon.svg');
            }
        }
    }
}