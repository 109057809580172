// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
// Regular ( Open Sans ) font family
$font-family-base:  'Volte', 'Arial', sans-serif !default;
// $font-family-hgroup:  'Open Sans', 'Arial', sans-serif !default;
$icon-family: "zealplus" !important;

// body contect text size
$font-size-base:  rem($base) !default; //16px (Don't change)

// Colors
$primary-100:   #F95E3D !default;
$secondary-100: #41C0B8 !default;

// Start with assigning color names to specific hex values.
$white:  #ffffff !default;
$white-800: #f8f8f8 !default;
$black:  #000000 !default;
$gray:   #dee3e2 !default;
$blue:   #183074 !default;
$text-color: #303F54 !default;
$orange: #F95E3D;
$grey :#707070 !default;
$lt-blue :#65BBE8 !default;
$yellow :#F9C428 !default;


// Body color
$body-bg:       $white !default;
$body-color:    #303F54 !default;

// Heading font Size
$headingSize: (
  'h1': rem(48px),
  'h2': rem(39px),
  'h3': rem(31px),
  'h4': rem(25px),
  'h5': rem(20px),
  'h6': rem(18px),
);

$hgroup-margin-bottom: rem(15px) !default;
$hgroup-font-family:   inherit !default;
$hgroup-font-weight:   500 !default;
$hgroup-line-height:   1.1 !default;
$hgroup-color:         $blue !default;

// Forms
$label-color:           $orange;
$label-font-weight:     400;
$label-font-family:     $font-family-base;
$label-font-size:       rem(14px);

$input-bg:                 $white;
$input-font-family:        $font-family-base;
$input-font-size:          rem(12px);
$input-font-weight:        400;
$input-color:              $blue;
$input-color-placeholder:  $grey;
$input-height-base:        rem(40px);
$input-radius:             5px;
$input-border-color:       rgba($text-color, .25);
$input-border-color-focus: #1160b0;
$textarea-height-base:     rem(150px);

// Set assets path
$img: '../images'; // background-img:url( #{$img}/)
$fonts: '../fonts';

// Container's maximum width
$container-width: rem(1156px) !default;
$gutters: rem(50px);
$row-gutters: rem(30px);

// Media Query breakpoints.
$breakpoints: (
  'sm': 576px, // Small devices (landscape phones, 576px and up)
  'md': 768px, // Medium devices (tablets, 768px and up)
  'lg': 992px, // Large devices (desktops, 992px and up)
  'xl': 1200px, // Extra large devices (large desktops, 1200px and up)
  'xxl': 1500px // Extra large devices (large desktops, 1400px and up)
);

// Advance utilities
$advance-utilities : true;

// Spacing : padding and margin
$spacing : true;
$spaceamounts: (30);

// Background and Text color classes(ex: .bg-white and .text-white)
$bgColor-map: (
  'white' : $white,
  'black' : $black
);

/* Note: If need Disable Decimal-value functionilty open function.scss file */