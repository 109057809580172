.full-width-cta{
    padding: 50px 0;
    background: #1160B0;
    background: linear-gradient(270deg, #1160B0 0%, #183074 100%);
    position: relative;
    @include respond-below(md){
        padding: 40px 0 43px;
    }
    &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(../images/testimonial-pattern.png) no-repeat center center;
        background-size: cover;
        mix-blend-mode: multiply;
        z-index: 0;
    }
    .full-width-cta-wrap{
        max-width: 835px;
        margin: auto;
        position: relative;
        h2{
            color: $white;
            &:last-child{
                margin-bottom: 0;
            }
            @include respond-below(md){
                margin-bottom: 8px;
            }
        }
        p{
            font-size: 18px;
            line-height: 1.3;
            color: #fff;
            @include respond-below(md){
                font-size: 16px;
                margin-bottom: 10px;
                line-height: 1.5;
            }
        }
        a.btn{
            @include respond-below(sm){
                display: block;
            }
        }
        
    }
}

.right-side-cta{
    .full-width-cta-wrap{
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 100%;
        @include respond-below(md){
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
        }
        .content-wrap{
            width: 100%;
            max-width: 640px;
            padding-right: 20px;
            @include respond-below(md){
                max-width: 100%;
                padding-right: 0;
                padding-bottom: 24px;
            }
            p{
                margin-bottom: 0;
            }
        }
        a.btn{
            min-width: 130px;
            @include respond-below(sm){
                width: 100%;
            }
        }
    }
}