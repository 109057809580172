.culture-promo{
    padding: 90px 0 115px;
    @include respond-below(xl){
        padding: 80px 0;
    }
    @include respond-below(lg){
        padding: 60px 0;
    }
    @include respond-below(md){
        padding: 40px 0 25px;
    }
    .left-col{
        @include respond-above(lg){
            width: 44.3%;
        }
        @include respond-below(lg){
            width: 100%;
        }
        .left-col-inner{
            padding-right: 50px;
            padding-top: 15px;
            @include respond-below(xl){
                padding-right: 0;
            }
            @include respond-below(lg){
                padding-top: 0;
            }
            h2{
                margin-bottom: 26px;
                @include respond-below(md){
                    margin-bottom: 16px;
                }
            }
        }
    }
    .right-col{
        @include respond-above(lg){
            width: 55.7%;
        }
        @include respond-below(lg){
            width: 100%;
            padding-top: 60px;
        }
        .image-wrap{
            display: flex;
            align-items: center;
            margin: 0 -35px;
            flex-wrap: wrap;
            @include respond-below(xl){
                margin: 0 -15px;
            }
            @include respond-below(md){
                max-width: 342px;
                margin: 0 auto;
            }
            .single-block{
                padding: 0 34px;
                width: 48%;
                @include respond-below(xl){
                    padding: 0 15px;
                }
                @include respond-below(md){
                    max-width: 265px;
                    margin: auto;
                    margin-bottom: 50px;
                    width: 100%;
                    padding: 0;
                }
                .image-block{
                    position: relative;
                    overflow: hidden;
                    border-radius: 5px;
                    figure{
                        position: relative;
                        padding-bottom: 123%;
                        @include imgFit;
                    }
                    .image-content{
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        z-index: 11;
                        color: #fff;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: flex-end;
                        background: linear-gradient(180deg, rgba(20, 104, 165, 0.03) 0%, #093767 100%);
                        opacity: 0;
                        visibility: hidden;
                        transition: all .5s;
                        cursor: pointer;
                        @include respond-below(xl){
                            opacity: 1;
                            visibility: visible;
                        }
                        p{
                            font-size: 14px;
                            line-height: 16px;
                            padding: 18px;
                        }
                    }
                    &:hover{
                        .image-content{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
                &:nth-child(2){
                    width: 52%;
                    @include respond-below(md){
                        width: 100%;
                        max-width: 100%;
                    }
                    .image-block{
                        figure{
                            padding-bottom: 68%;
                        }
                    }
                }
            }
        }
    }
}