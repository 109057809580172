.feature-state{
    padding: 115px 0 80px;
    // background-image: url(../images/dott-circle.svg);
    // background-position: center;
    // background-repeat: no-repeat;
    position: relative;
    overflow: hidden;

    @include respond-below(xl){
        padding: 80px 0;
    }
    @include respond-below(md){
        padding: 70px 0 20px;
    }
    .circle-image{
        position: absolute;
        top: -170px;
        left: 0;
        right: 0;
        margin: auto;
        width: 741px;
        z-index: -1;
        transform: rotate(1080deg);
        opacity: 0;
        transition: all 1s;
        // img{
        //     filter: grayscale(1);
        // }
    }
    .section-title{
        max-width: 700px;
        margin: auto;
        padding-bottom: 55px;
        @include respond-below(xl){
            padding-bottom: 40px;
        }
        @include respond-below(lg){
            padding-bottom: 25px;
        }
        @include respond-below(md){
            margin-bottom: 10px;
        }
        p{
            font-size: 20px;
            margin-bottom: 40px;
            color: #727272;
            @include respond-below(xl){
                font-size: 18px;
            }
            @include respond-below(lg){
                margin-bottom: 20px;
            }
            @include respond-below(md){
                font-size: 20px;
            }
        }
    }
    .states{
        padding-top: 60px;
        @include respond-below(xl){
            padding-top: 40px;
        }
        @include respond-below(lg){
            padding-top: 25px;
        }
        @include respond-below(md){
            position: relative;
            .circle-image{
                position: absolute;
                top: -270px;
                left: 50%;
                margin: auto;
                width: 740px;
                text-orientation: le;
                transform: translateX(-50%);
            }
        }
        .states-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin: 0 -15px;
            @include respond-below(md){
                display: block;
            }
            .single-state{
                width: 25%;
                padding: 0 15px;
                @include respond-below(md){
                    width: 100%;
                    max-width: 200px;
                    margin: auto;
                    margin-bottom: 40px;
                }
                .counter-wrap-block{
                    height: 84px;
                    overflow: hidden;
                    margin-bottom: 13px;
                    h2:empty{
                        display: none;
                    }
                    .counter-wrap{
                        transition: all 1s;
                    }
                }
                h2{
                    font-size: 76px;
                    margin-bottom: 13px;
                    color: #1C3270;
                    @include respond-below(xl){
                        font-size: 65px;
                    }
                    @include respond-below(lg){
                        font-size: 50px;
                    }
                    @include respond-below(md){
                        font-size: 70px;
                        line-height: 1;
                        margin-bottom: 5px;
                    }
                }
                p{
                    font-size: 20px;
                    color: #727272;
                    @include respond-below(xl){
                        font-size: 18px;
                    }
                    @include respond-below(lg){
                        font-size: 16px;
                    }
                }
                &.animate{
                    .counter-wrap-block{
                        .counter-wrap{
                            transform: translateY(-100%) translatey(84px);
                        }
                    }
                }
            }
        }
    }
    &.animate{
        .circle-image {
            transform: rotate(0);
            opacity: 1;
        }
    }
}