.case-study-detail{
    margin-top: -20vh;
    position: relative;
    padding-bottom: 15px;
    @include respond-below(md){
        margin-top: -11vh;
        overflow:hidden ;
    }
    .circle-image{
        position: absolute;
        bottom: 6%;
        left: 0%;
        transform: translateX(-50%) rotate(180deg);
        z-index: 0;
        max-width: 741px;
        // filter: grayscale(1);
        @include respond-below(md){
            position: absolute;
            bottom: 15%;
            left: inherit;
            transform: translateX(66%) rotate(180deg);
            z-index: 0;
            filter: grayscale(1);
            width: 550px;
            height: 550px;
            right: 0;
        }
    }
}
.detail-content{
    
    .banner-details{
        max-width:855px;
        margin: auto;
        background: $white;
        padding: 30px 32px 27px;
        @include respond-below(lg){
            padding: 30px 20px 25px;
        }
        @include respond-below(md){
            padding:20px 17px 25px;
        }
    }
    .asset-type {
        font-size: 14px;
        font-weight: bold;
        color: $secondary-100;
        margin-bottom: 10px;
        a{
            text-decoration: none;
            color: $secondary-100;
            &:hover{
                color: $orange;
            }
        }
    }
    h1{
        margin-bottom: 26px;
    }
    .author-name{
        color: #807DB5;
        margin-bottom: 8px;
    }
    .tags{
        font-weight: bold;
        text-transform: uppercase;
        margin-left: -9px;
        margin-bottom: 8px;
        span{
            margin-left: 3px;
            color: #807DB5;
            a {
                text-decoration: none;
                color: #807DB5;
                &:hover{
                    color: $secondary-100;
                }
            }
        }
    }
    .post-date{
        color: #807DB5;
        font-weight: bold;
    }
    .wysiwyg-content{
        h5{font-weight: 600;}
        &-wrap{
            max-width:855px;
            margin: auto;
            background: $white;
            padding: 0px 32px;
            @include respond-below(lg){
                padding: 0 20px;
            }
            @include respond-below(md){
                padding: 0;
            }
            .two-column{
                padding-bottom: 24px;
                .cell-6{
                    @include respond-below(md){
                        width: 100%;
                        &:not(:last-child){
                            padding-bottom: 24px;
                        }
                    }
                }
            }
        }
        h2{
            + p{
                margin-bottom: 40px;
                @include respond-below(lg){
                    margin-bottom: 20px;
                }
            } 
        }
        h4{
            color: #807DB5;
            @include respond-below(md){
                font-size: 20px;
                line-height: 26px;
            }
        }
        p{
            margin-bottom: 24px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        ul{
            padding: 0;
            margin: 0;
            list-style: none;
            li{
                padding: 0 0 10px 22px;
                position: relative;
                &:before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 10px;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: #2DAEA8;
                }
            }
             ul {
                margin-top: 23px;
            }
        }
        ol{
            padding: 0;
            margin: 0;
            list-style: none;
            counter-reset: counter-1;
            li{
                padding: 0 0 10px 22px;
                position: relative;
                counter-increment: counter-1;
                &:before{
                    content: counter(counter-1) ". ";
                    position: absolute;
                    left: 0%;
                    top: 4px;
                    font-weight: 500;
                    line-height: 20px;
                    color: $secondary-100;
                }
            }
            ol{
                margin-top: 24px;
                counter-reset: counter-2;
                li{
                    counter-increment: counter-2;
                    &:before{
                        content: counter(counter-2,lower-alpha) ". ";
                        top: 2px;
                    }
                }
            }
        }
    }
    .video-module{
        padding: 43px 0;
        text-align: center;
        @include respond-below(md){
            padding: 30px 0;
        }
        .block-media{
            max-width:562px;
            margin: auto;
            position: relative;
            a{
                position: relative;
                display: block;
                &:after{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba($grey, .34);
                    z-index: 0;
                }
            }
            .play-ico{
                max-width: 90px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                @include respond-below(lg){
                    max-width:75px ;
                }
                @include respond-below(md){
                    max-width: 55px;
                }
            }
        }
    }
}

.in-line-listing-promo {
    .container{
        padding: 0 32px;
        @include respond-below(md){
            padding: 0;
        }
    }
    .inner-block {
        border-top: 1px solid rgba($grey, .25);
        border-bottom: 1px solid rgba($grey, .25);
        padding: rem(40px) 0 rem(50px);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        max-width: 958px;
        margin: auto;
        @include respond-below(md){
            padding: 24px 0;
        }

        .img-block {
            max-width: rem(265px);

            @include respond-below(sm) {
                width: 100%;
                max-width: 100%;
            }

            img {
                width: 100%;
            }
        }

        .content-block {
            padding-left: rem(32px);
            padding-right: rem(40px);
            max-width: calc(100% - 265px);
            @include respond-below(lg){
                padding-right: rem(15px);
            }
            @include respond-below(sm) {
                max-width: 100%;
                padding-left: 0;
                padding-right: 0;
                padding-top: rem(30px);
            }

            h3 {
                margin-bottom: rem(21px);
                // line-height: rem(35px);

                @include respond-below(md) {
                    font-size: 24px;
                    line-height: 28px;
                }
            }
            a.btn{
                @include respond-below(md){
                    width: 100%;
                }
            }
        }

    }
    + .wysiwyg-content{
        margin-top: 63px;
        @include respond-below(md){
            margin-top: 27px;
        }
    }
}
.social-share-icons{
    position: absolute;
    top: 125;
    width: 44px;
    @include respond-below(xl){
        position: inherit;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 40px;
    }
    span{
        font-size: 16px;
        line-height: 18px;
        font-weight: bold;
        color: $yellow;
        margin-bottom: 22px;
        display: block;
        @include respond-below(xl){
            margin-bottom: 0;
            padding: 0 13px;
        }
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: center;
        @include respond-below(xl){
            display: flex;
            align-items: center;
            justify-content: center;
        }
        li{
            padding: 0 0 28px 0;
            @include respond-below(xl){
                padding: 0 13px;
            }
            a{
                display: block;
                transition: all .5s;
                &:hover{
                    filter: grayscale(1);
                }
            }
        }
    }
    + div{
        @include respond-above(xl){
            pointer-events: none;
        }
    }
}