.related-resources{
    padding: 43px 0 107px;
    @include respond-below(xl){
        padding: 40px 0 80px;
    }
    @include respond-below(lg){
        padding: 60px 0 80px;
    }
    @include respond-below(md){
        padding: 60px 0 40px;
    }
    .section-heading{
        padding-bottom: 30px;
        @include respond-below(lg){
            padding-bottom: 16px;
        }
        h2{
            padding-bottom: 30px;
            &:after{
                background: #E86849;
            }
        }
    }
}
.blogs-wrap{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    transform: translateY(100px);
    opacity: 0;
    &.animate{
        transform: translateY(0);
        opacity: 1;
    }
    @include respond-below(lg){
        margin: 0 -10px;
    }
    .single-blog{
        width: 33.33%;
        padding: 0 20px;
        @include respond-below(lg){
            padding: 0 10px;
        }
        @include respond-below(md){
            width: 100%;
        }
        .blog-image{
            figure{
                position: relative;
                padding-bottom: 66.7%;
               @include imgFit;
            }
        }
        .blog-content{
            padding: 30px 0;
            @include respond-below(md){
                padding: 20px 0 32px;
            }
            h5{
                margin-bottom: 10px;
                color: #1160B0;
                font-size: 19px;
                line-height: 24px;
                @include respond-below(md){
                    font-size: 19px;
                    line-height: 24px;
                }
            }
            p{
                margin-bottom: 25px;
                color: $blue;
                @include respond-below(md){
                    margin-bottom: 20px;
                }
            }
        }
    }
}