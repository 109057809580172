/* responsive nav for small devices */
$mbnav: ".mbnav";
$mbbg: $secondary-100;
$line: $secondary-100;
$text-color: $white;
$menulevel1: 18px;
$menulevel2: 16px;
$menulevel3: 14px;
$hamburger: ".hamburger";
$humburgerBase: 30px;

// Humburger
#{$hamburger} {
    --humburgerBox: #{rem(31px)};
    display: none;
    align-items: center;
    justify-content: center;
    width: var(--humburgerBox);
    height: var(--humburgerBox);
    padding: 0;
    margin: 0;
    text-align: center;
    background-color: transparent;
    z-index: 999;
    // @include mobile {
    //     display: flex;
    // }
    @include respond-below(md){
        display: flex;
    }
    &:hover,
    &:focus {
        background-color: transparent;
        outline: none;
        cursor: pointer;
    }

    span {
        position: relative;
        left: 0;
        right: 0;
        display: block;
        margin: 0 auto;
        border-radius: 0;
        height: 2px;
        width: 100%;
        background: #1160B0;
        transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
        cursor: pointer;
        border-radius: 2px;

        &:before,
        &:after {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            background: #1160B0;
            transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
            border-radius: 2px;
        }

        &:before {
            top: -8px;
        }

        &:after {
            bottom: -8px;
        }
    }
    &.is-clicked {
        span {
            background-color: transparent !important;

            &:before,
            &:after {
                top: 0;
                background-color: #1160B0;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: translateY(-6px) rotate(-45deg);
                top: 6px;
            }
        }
    }
}

// mobile nav
#{$mbnav} {
    position: relative;
    display: none;
    z-index: 999;

    @include respond-below(lg) {
        display: block;
    }

    &.is-open {
        .btn-wrap {
            transform: translateY(0);
        }
        + header {
            // position: fixed;
        }
    }

    &__state {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 100%;
        width: 100%;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        z-index: 999;
        pointer-events: none;
    }

    &__inner {
        position: relative;
        max-height: 100vh;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        background: rgba($mbbg, 1);
    }

    // mobile menu logo
    &__logo {
        color: $white;
        padding: 15px;
    }

    .menu-wrap {
        --leftSlide: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: var(--leftSlide);
        transition: 0.4s ease;

        .menu-wrap {
            right: -100%;
            opacity: 0;
            visibility: hidden;
        }

        > .menu-inner {
            max-height: 100%;
            padding-top: rem(85px);
            padding-bottom: rem(20px);
            background-color: $white;
            pointer-events: auto;
            overflow: hidden auto;
        }
    }

    // menu
    ul {
        position: static;
        left: 0;
        width: 100%;
        // max-height: calc(100vh - 145px);
        margin: 0;
        padding: 0 15px;
        transition: 0.4s ease;
        // overflow: hidden auto;

        li {
            &.is-open {
                > .menu-wrap {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        li:not(.btn-wrap) {
            position: static;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0;
            margin: 0 0 0 0;
            list-style: none;
            color: $primary-100;
            font-size: rem(16px);
            font-weight: 500;
            

            &:last-child {
                border-bottom: 0;
            }

            &::before {
                display: none;
            }

            &.has-sub {
                a {
                    &::after {
                        display: none;
                    }
                }
            }

            %aAfter {
                content: "";
                --sArrowWidth: 10px;
                display: flex;
                align-items: center;
                margin: auto;
                width: var(--sArrowWidth);
                height: var(--sArrowWidth);
                border: calc(var(--sArrowWidth) / 4) solid $primary-100;
                border-left: 0;
                border-bottom: 0;
                transform: rotate(45deg);
            }

            a {
                position: relative;
                display: block;
                padding: 10px 0;
                color: $blue;
                line-height: 1.5;
                text-decoration: none !important;
                z-index: 2;
                cursor: pointer;
                flex: 1 1 auto;
                order: -1;

                &:hover {
                    // color: $white;
                }

                &::after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin-left: auto;
                    margin-right: 0;
                    @extend %aAfter;
                    display: none;
                }
            }

            @at-root #{$mbnav}__caret {
                display: grid;
                align-items: center;
                justify-content: flex-end;
                width: 30px;
                height: auto;
                cursor: pointer;
                z-index: 1;

                &:not(.trigger-caret) {
                    pointer-events: none;
                }

                &::before {
                    @extend %aAfter;

                    .color-mbnav & {
                        color: $primary-100 !important;
                    }
                }
            }

            &.is-open {
                > #{$mbnav}__caret:after {
                    transform: rotate(0);
                }
            }

            &.has-sub {
                > a {
                    flex: 1 1 auto;
                }
            }

            .btn-wrap {
                position: fixed;
                bottom: 20px;
                left: 20px;
                width: calc(100% - 40px);
                margin: 0;
                transform: translateY(200%);
                transition: 0.4s ease-in-out;

                &::before {
                    display: none;
                }

                a {
                    display: block;
                }
            }
        }

        ul {
            li {
                font-size: $menulevel2;

                a {
                    padding-left: 25px;
                }

                li {
                    font-size: $menulevel3;

                    a {
                        padding-left: 35px;
                    }
                }
            }
        }

        > li {
            > .sub-menu {
                position: absolute;
                left: 100%;
            }
        }

        .back-click {
            width: 100%;
            order: -1;
            color: currentColor;
            padding: 15px 0 15px 15px;
            font-weight: 600;
            position: relative;

            &::before {
                // @include icon("a");
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%) rotate(-135deg);
                display: inline-block;
                width: 7px;
                height: 7px;
                border: 2px solid #1C3270;
                color: $primary-100;
                border-left: 0;
                border-bottom: 0;
                // transform: scaleX(-1);
                
            }
        }
    }

    // backdrop
    &__backdrop {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        visibility: hidden;
        transition: all 0.4s ease-in;
        background: rgba($black, 0.55);
        opacity: 0;
        z-index: -1;
    }

    &__overlay {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: all 0.4s ease-in;
        background: rgba(112,112,113, 0.36);
        opacity: 0;
        visibility: hidden;
        z-index: 99;

        &.is-show {
            opacity: 1;
            visibility: visible;
        }
    }

    // after open
    &.is-open {
        #{$mbnav}__state {
            left: 0;
        }

        #{$mbnav}__backdrop {
            opacity: 1;
            z-index: 999;
            visibility: visible;
        }
    }
}


/*********Extra added for mobile menu************/
.scroll-fixed{
    .main-header{
        box-shadow: 0 2px 6px rgba(0, 0, 0, .16);
    }
}
.mbnav{
    .menu{
        .menu-wrap{
            opacity: 1;
            visibility: visible;
        }
        .sub-menu{
            .menu-wrap{
                position: inherit;
                transition: inherit;
                display: none;
                opacity: 1;
                visibility: visible;
                .menu-inner{
                    padding: 0 0 10px !important;
                }
            }
            .inner-tab-content{
                padding: 0 0 0 30px;
                li{
                    margin-bottom: 10px;
                    a{
                        padding: 5px 0;
                        color: $secondary-100;
                        &:hover{
                            color: $body-color;
                        }
                    }
                    p{
                        color: $body-color;
                        width: 100%;
                    }
                    &:first-child{
                        display: none;
                    }
                }  
            }
            li{
                &.is-open{
                    .mbnav__caret{
                        &:before{
                            transform: rotate(135deg);
                        }
                    }
                }
            }
        }
    }
}

