.stat-text-5050{
    padding: 15px 0;
    position: relative;
    // max-width: 1410px;
    // overflow: hidden;
    margin: auto;
    @include respond-below(md){
        padding-top: 0;
        overflow:hidden ;
    }
    .circle-image{
        position: absolute;
        right: 33.6rem;
        transform: translateX(-50%);
        width: 38.7%;
        top: -78px;
        pointer-events: none;
        @include respond-below(xxl) {
            right: 20rem;
            width: 51.4%;
            top: -84px;
        }
        @include respond-below(xl) {
            right: 17rem;
        }
        @include respond-below(lg){
            right: 3.6rem;
            width: 70%;
        }
        @include respond-below(md){
            width: 738px;
            height: 738px;
            right: -460px;
            transform: inherit;
            top: -42px;
        }
    }
    .container{
        @include respond-below(md){
            overflow:hidden;
        }
    }
    .tabbing-wrap{
        position: relative;
        z-index: 1;
    }
    .tabs-nav-wrap{
        width: 50%;
        padding-right: 52px;
        padding-top: 52px;
        padding-left: 172px;
        @include respond-below(xl) {
            padding-left: 120px;
        }
        @include respond-below(lg){
            padding-left:80px;
            padding-right: 15px;
            padding-top: 15px;
        }
        @include respond-below(md){
            width: 100%;
            padding: 15px 15px 0 15px;
            box-shadow: 0 31px 50px rgba($body-color, 0.11);
            background: #fff;
            z-index: 1;
        }
        .tab-nav{
            @include respond-below(md){
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            .tab-nav-link{
                font-size: 55px;
                line-height: 1.07;
                color: rgba(17, 97, 176, 0.25);
                padding-left: 28px;
                margin-bottom: 15px;
                font-weight: 500;
                transition: all .5s;
                @include respond-below(xl) {
                    font-size: 50px;
                }
                @include respond-below(lg) {
                    font-size: 45px;
                    padding-left: 15px;
                }
                @include respond-below(md) {
                    font-size: 25px;
                    padding: 0 10px 6px;
                    margin-bottom: 0;
                }
                &.active{
                    padding-left: 0;
                    margin-bottom: 0;
                    color: #183074;
                    font-size: 117px;
                    line-height: 1.1;
                    cursor: pointer;
                    @include respond-below(xl) {
                        font-size: 80px;
                    }
                    @include respond-below(lg){
                        font-size: 62px;
                    }
                    @include respond-below(md){
                        font-size: 25px;
                        padding-left: 10px;
                        border-bottom: 6px solid $orange;
                    }
                }
            }
        }
    }
    .tabs-main-content{
        padding: 0 15px;
        width: 50%;
        @include respond-below(md){
            width: 100%;
        }
        .tabs-main-content-wrap{
            padding: 69px 42px 69px 58px;
            @include respond-below(xl) {
                padding: 40px 0 40px 30px;
            }
            @include respond-below(lg) {
                padding: 10px 0 ;
            }
            @include respond-below(md){
                padding: 40px 0 3px;
            }
            .tab-content{
                display: none;
            }
            h2{
                padding-bottom: 12px;
                position: relative;
                margin-bottom: 25px;
                color: $secondary-100;
                &:after{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 50px;
                    height: 1px;
                    background: $orange;
                }
            }
            p{
                &:last-of-type{
                    margin-bottom: 50px;
                    @include respond-below(lg) {
                        margin-bottom: 30px;
                    }
                    @include respond-below(md) {
                        margin-bottom: 43px;
                    }
                }
            }
        }
    }
}