.careers{
.image-text-5050{
    padding: 95px 0;
    position: relative;
    @include respond-below(xl){
        padding: 80px 0 40px;
    }
    @include respond-below(md){
        padding: 70px 0 40px;
    }
    .section-heading{
        padding-bottom: 72px;
        @include respond-below(xl) {
            padding-bottom: 50px;
        }
        @include respond-below(md){
            padding-bottom: 30px;
        }
    } 
    .circle-image{
        position: absolute;
        top: 52.3%;
        transform: translate(-50%, -50%);
        left: calc(50% - 320px);
        width: 100%;
        max-width: 741px;
        // mix-blend-mode: luminosity;
        @include respond-below(lg){
            left: calc(50% - 150px);
        }
        @include respond-below(md){
            display: none;
        }
    }   
}
.image-text-5050-wrap{
    padding: 0 3px;
}
.promo-block{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 70px;
    @include respond-below(lg){
        padding-bottom: 50px;
    }
    @include respond-below(md){
        padding-bottom: 30px;
    }
    .img-part{
        margin-right: -40px;
        max-width: calc(40.3% + 40px);
        width: 100%;
        @include respond-below(lg){
            max-width: calc(45% + 40px);
        }
        @include respond-below(md){
            max-width: 100%;
        }
        .inner-img{
            height: 100%;
        }
        figure{
            position: relative;
            height: 100%;
            border-radius: 5px;
            overflow:hidden;
            img{
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
            &:after{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(114,114,114,0) 0%, rgba(14,45,87,1) 100%);
                opacity: 0.69;
                display: none;
                @include respond-below(md){
                    display: block;
                }
            }
        }
    }
    .content-part{
        padding: rem(40px) 0;
        max-width: 59.7%;
        position: relative;
        @include respond-below(lg){
            max-width: 55%;
        }
        @include respond-below(md){
            max-width: 100%;
            padding: 0 18px;
            margin-top: -30px;
        }
        .inner-content{
            box-shadow: 0 0 36px rgba(0,0,0,.08);
            background: $white;
            height: 100%;
            padding: 34px 70px 40px;
            border-radius: 5px;
            @include respond-below(xl) {
                padding:50px 40px;
            }
            @include respond-below(lg) {
                padding:40px 30px;
            }
            @include respond-below(md) {
                padding:33px 17px 35px;
                border-radius: 0px;
            }
            h4{
                margin-bottom: 23px;
                color: #1C3270;
                @include respond-below(lg){
                    margin-bottom: 15px;
                }
                @include respond-below(md){
                    margin-bottom: 8px;
                    font-size: 29px;
                    color: #807DB5;
                    line-height: 1;
                }
            }
            p{
                font-size: 16px;
                line-height: 1.5;
                font-weight: 400;
                margin-bottom: 32px;
                color: $grey;
                @include respond-below(xl) {
                    font-size: 18px;
                }
                @include respond-below(lg){
                    margin-bottom: 15px;
                }
                @include respond-below(md) {
                    font-size: 16px;
                    margin-bottom: 17px;
                }
            }
            a{
                // font-family: 'Quatro Slab';
                color: $secondary-100;
                &:after{
                    background: url(../images/short-arrow.svg) no-repeat;
                    width: 6px;
                    height: 10px;
                }
            }
        }
    }
    &.right-img{
        flex-direction: row-reverse;
        .img-part{
            margin-left: -8.8%;
            max-width: 53.3%;
            margin-right: 0;
            @include respond-below(md){
                max-width: 100%;
            }
        }
    }
    
}
}