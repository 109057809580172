.tabbed-promo{
    padding: 51px 0 66px;
    @include respond-below(lg){
        padding: 40px 0 60px;
    }
    @include respond-below(md){
        background: #f4f4f4;
        padding: 45px 0 00px;
    }
    .section-heading{
        padding-bottom: 15px;
        @include respond-below(md){
            padding-bottom: 0;
        }
    }
    .support-tab{
        overflow: hidden;
        .tab-list {
            position: relative;
            &:after{
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 200%;
                box-shadow: 0 23px 50px 0px rgb(48 63 84 / 11%);
                z-index: -1;
            }
            .category-dropdown{
                @include respond-above(md){
                    display: none;
                }
            }
            .category-dropdown{
                @include respond-below(md){
                    display: block;
                    padding: 20px;
                    font-size: 18px;
                    border: 1px solid $orange;
                    line-height: 22px;
                    font-weight: 500;
                    color: $orange;
                    background: $white;
                    position: relative;
                    &:after{
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 26px;
                        width: 12px;
                        height: 12px;
                        border: 3px solid #F95E3D;
                        border-left: none;
                        border-bottom: none;
                        transform: translateY(-50%) rotate(135deg);
                        transition: all .5s;
                    }
                    &.active{
                        border-bottom: 0;
                        &:after{
                            transform: translateY(-50%) rotate(-45deg);
                        }
                    }
                    
                }
            }
        }
        .tab-nav{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            @include respond-above(md){
                display: flex !important;
            }
            @include respond-below(md){
                display: none;
                border:1px solid $orange;
                border-top: 0;
                background: $white;
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                width: calc(100% - 32px);
            }
            .single-nav{
                padding: 0 15px;
                margin-right: 50px;
                font-size: 20px;
                padding-bottom: 12px;
                color: rgba(47, 47, 47,.5);
                position: relative;
                cursor: pointer;
                @include respond-below(xl){
                    margin-right: 20px;
                }
                @include respond-below(lg){
                    margin-right: 12px;
                    padding: 0 10px 10px;
                    font-size: 16px;
                }
                @include respond-below(md){
                    margin-right: 0;
                    padding: 0 20px 10px 20px;
                    font-size: 18px;
                    &:after{
                        display: none;
                    }
                }
                &:after{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 5px;
                    background: $orange;
                    transition: all .5s;
                }
                &:last-child{
                    margin-right: 0;
                }
                &.active{
                    color: $orange;
                    font-weight: 500;
                    &:after{
                        width: 100%;
                    }
                }
            }
        }
        .tab-content-wrap{
            padding: 55px 0;
            @include respond-below(lg){
                padding: 40px 0;
            }
            @include respond-below(md){
                padding: 25px 0 35px;
            }
            .tab-content{
                display: none;
                .inner-tab-content{
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 -15px;
                    @include respond-below(md){
                        flex-direction: column-reverse;
                    }
                    .left-col{
                        width: 58.5%;
                        padding: 0 15px;
                        padding-left: 115px;
                        padding-right: 100px;
                        display: flex;
                        flex-wrap: wrap;
                        @include respond-below(xl){
                            padding-left: 40px;
                            padding-right: 60px;
                        }
                        @include respond-below(lg){
                            padding: 0 15px;
                        }
                        @include respond-below(md){
                            width: 100%;
                            padding-top: 32px;
                            .half-col{
                                &:not(:last-child){
                                    padding-bottom: 50px;
                                }
                            }
                        }
                        p{
                            margin-bottom: 20px;
                            @include respond-below(md){
                                margin-bottom: 10px;
                            }
                        }
                        
                        ul{
                            padding: 0;
                            list-style: none;
                            margin-bottom: 27px;
                            width: 100%;
                            @include respond-below(md){
                                margin-bottom: 0;
                            }
                            li{
                                padding: 0 0 10px 35px;
                                position: relative;
                                &:before{
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: 3px;
                                    width: 19px;
                                    height: 19px;
                                    background: image('tick-icon-green.svg');
                                }
                            }
                        }
                        ol{
                            padding: 0;
                            margin: 0;
                            list-style: none;
                            counter-reset: counter-1;
                            margin-bottom: 27px;
                            width: 100%;
                            li{
                                padding: 0 0 10px 22px;
                                position: relative;
                                counter-increment: counter-1;
                                &:before{
                                    content: counter(counter-1) ". ";
                                    position: absolute;
                                    left: 0%;
                                    top: 4px;
                                    font-weight: 500;
                                    line-height: 20px;
                                    color: $secondary-100;
                                }
                            }
                            ol{
                                margin-top: 23px;
                                counter-reset: counter-2;
                                margin-bottom: 0;
                                li{
                                    counter-increment: counter-2;
                                    &:before{
                                        content: counter(counter-2,lower-alpha) ". ";
                                        top: 2px;
                                    }
                                }
                            }
                        }
                        .btn-link {
                            color: $secondary-100;
                            &:after{
                                background: image('short-arrow.svg') no-repeat;
                                width: 6px;
                                height: 10px;
                                
                            }
                        }
                        
                    }
                    .right-col{
                        padding: 0 15px;
                        width: 41.5%;
                        padding-top: 5px;
                        @include respond-below(md){
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}