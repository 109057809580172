// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
// @import font('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@font-face {
    font-family: 'Volte';
    src: font('Volte-Medium.eot');
    src: font('Volte-Medium.eot?#iefix') format('embedded-opentype'),
        font('Volte-Medium.woff2') format('woff2'),
        font('Volte-Medium.woff') format('woff'),
        font('Volte-Medium.ttf') format('truetype'),
        font('Volte-Medium.svg#Volte-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Volte';
    src: font('Volte-Regular.eot');
    src: font('Volte-Regular.eot?#iefix') format('embedded-opentype'),
        font('Volte-Regular.woff2') format('woff2'),
        font('Volte-Regular.woff') format('woff'),
        font('Volte-Regular.ttf') format('truetype'),
        font('Volte-Regular.svg#Volte-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Volte';
    src: font('Volte-Semibold.eot');
    src: font('Volte-Semibold.eot?#iefix') format('embedded-opentype'),
        font('Volte-Semibold.woff2') format('woff2'),
        font('Volte-Semibold.woff') format('woff'),
        font('Volte-Semibold.ttf') format('truetype'),
        font('Volte-Semibold.svg#Volte-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Volte';
    src: font('Volte-Bold.eot');
    src: font('Volte-Bold.eot?#iefix') format('embedded-opentype'),
        font('Volte-Bold.woff2') format('woff2'),
        font('Volte-Bold.woff') format('woff'),
        font('Volte-Bold.ttf') format('truetype'),
        font('Volte-Bold.svg#Volte-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


