/* Reset css
   ========================================================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
a,
nav,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	vertical-align: baseline;
	font-size: inherit;
	background: transparent;
	border: 0;
	outline: none;
	-webkit-font-smoothing: subpixel-antialiased;
	-webkit-tap-highlight-color: rgba($black, 0);
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

article,
aside,
footer,
header,
nav,
section,
figcaption,
figure,
main {
	display: block;
}

/* Text-level semantics
   ========================================================================== */
sub,
sup {
	position: relative;
	vertical-align: baseline;
	font-size: 75%;
	line-height: 0;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

/* Embedded content
   ========================================================================== */
audio,
video,
iframe {
	display: inline-block;
}
