.half-col{
    padding: 0 15px;
    width: 100%;
    @include respond-above(md){
        width: 50%;
    }
}
.two-column-text-promo{
    padding: 75px 0 70px;
    @include respond-below(md){
        padding: 46px 0 50px;
    }
    .left-col{
        @include respond-below(md){
            padding-bottom: 15px;
        }
        h2{
            margin-bottom: 10px;
        }
        p{
            max-width: 463px;
            line-height: 22px;
            color: #727272;
            @include respond-below(md){
                line-height: 24px;
            }
        }
    }
    .right-col{
        padding-top: 9px;
        h3{
            margin-bottom: 11px;
            color: #2DAEA8;
            @include respond-below(md){
                font-size: 24px;
            }
        }
        ul{
            padding: 0;
            list-style: none;
            margin-bottom: 27px;
            @include respond-below(md){
                margin-bottom: 0;
            }
            li{
                padding: 0 0 14px 37px;
                position: relative;
                color: $grey;
                font-weight: 500;
                &:before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 3px;
                    width: 19px;
                    height: 19px;
                    background: image('tick-icon-green.svg');
                }
            }
        }
    }
}