.events{
    .inner-banner{
        padding: 60px 0 100px;
        @include respond-below(md){
            padding: 50px 0 100px;
            min-height: 340px;
        }
        .inner-banner-wrap{
            .breadcum{
                margin-bottom: 30px;
                @include respond-below(md){
                    margin-bottom: 3px;
                }
            }
        } 
        .banner-content{
            @include respond-above(lg){
                max-width: 585px;
            }
            h1{
                margin-bottom: 18px;
                @include respond-below(md){
                    margin-bottom: 4px;
                }
            }
            p{
                font-size: 25px;
                @include respond-below(lg){
                    font-size: 22px;
                }
                @include respond-below(md){
                    font-size: 16px;
                }
            }
        }
    }
    .pagination{
        display: flex;
    }
}
.events-section{
    padding-bottom: 90px;
    @include respond-below(md){
        padding-bottom: 65px;
    }
    .event-listing{
        max-width: 959px;
        margin: auto;
        padding: 37px 0 0;
        @include respond-below(md){
            padding: 28px 0 0 ;
        }
        .single-event{
            padding-bottom: 20px;
            padding-top: 22px;

            &:not(:last-child){
                border-bottom: 1px solid rgba(114, 114, 114, .2);
            }
            @include respond-below(md){
                border-bottom: 1px solid rgba(114, 114, 114, .2);
            }
            .label-wrap{
                display: flex;
                flex-wrap: wrap;
                font-weight: 700;
                font-size: 14px;
                margin-bottom: 11px;
                .event-name{
                    color: #44BCED;
                    margin-right: 23px;
                    @include respond-below(md){
                        margin-right: 15px;
                    }
                }
                .date{
                    color: $secondary-100;
                    margin-right: 11px;
                }
                .time{
                    color: $orange;
                }
            }
            .event-content{
                padding-bottom: 24px;
                @include respond-below(md){
                    padding-bottom: 5px;
                }
                h4{
                    line-height: 1.16;
                    margin-bottom: 5px;
                    @include respond-below(md){
                        font-size: 25px;
                        margin-bottom: 10px;
                    }
                    a{
                        text-decoration: none;
                        &:hover{
                            color: $secondary-100;
                        }
                    }
                }
            }
            .event-location{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                span{
                    @include respond-below(md){
                        display: block;
                        padding-left: 35px;
                    }
                }
                .location-text{
                    padding: 5px 0;
                    margin-right: 16px;
                    font-weight: bold;
                    padding-left: 35px;
                    position: relative;
                    @include respond-below(md){
                        padding-bottom: 0;
                    }
                    &:before{
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 22px;
                        height: 32px;
                        background: image('map-marker.svg') no-repeat;
                        background-size: cover;
                        @include respond-below(md){
                            top: 12px;
                        }
                    }
                    a{
                        text-decoration: none;
                        color: #303F54;
                        &:hover{
                            color: $secondary-100;
                        }
                    }
                }
            }
        }    
    }
}