.has-animation{
    transition: all .8s;
}
.image-text-5050{
    padding: 95px 0;
    position: relative;
    @include respond-below(xl){
        padding: 80px 0 40px;
    }
    @include respond-below(md){
        padding: 70px 0 40px;
    }
    .section-heading{
        padding-bottom: 72px;
        @include respond-below(xl) {
            padding-bottom: 50px;
        }
        @include respond-below(md){
            padding-bottom: 30px;
        }
    } 
    .circle-image{
        position: absolute;
        top: 56.3%;
        transform: translate(-50%, -50%);
        left: 50%;
        width: 100%;
        max-width: 741px;
        // mix-blend-mode: luminosity;
        @include respond-below(md){
            display: none;
        }
    }   
}
.image-text-5050-wrap{
    padding: 0 3px;
}
.promo-block{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 70px;
    @include respond-below(lg){
        padding-bottom: 50px;
    }
    @include respond-below(md){
        padding-bottom: 30px;
    }
    .img-part{
        margin-right: -11.3%;
        max-width: 55.8%;
        width: 100%;
        transform: translateX(-100%);
        opacity: 0;
        &.animate{
            transform: translateX(0);
            opacity: 1;
        }
        @include respond-below(md){
            max-width: 100%;
        }
        .inner-img{
            height: 100%;
        }
        figure{
            position: relative;
            height: 100%;
            border-radius: 5px;
            overflow:hidden;
            img{
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
            &:after{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(114,114,114,0) 0%, rgba(14,45,87,1) 100%);
                opacity: 0.69;
            }
        }
    }
    .content-part{
        padding: rem(40px) 0;
        max-width: 55.5%;
        position: relative;
        transform: translateX(100%);
        opacity: 0;
        &.animate{
            transform: translateX(0);
            opacity: 1;
        }
        @include respond-below(md){
            max-width: 100%;
            padding: 0 18px;
            margin-top: -30px;
        }
        .inner-content{
            box-shadow: 0 0 36px rgba(0,0,0,.08);
            background: $white;
            height: 100%;
            padding: 80px 65px 65px;
            @include respond-below(xl) {
                padding:50px 40px;
            }
            @include respond-below(lg) {
                padding:40px 30px;
            }
            @include respond-below(md) {
                padding:44px 17px 35px;
            }
            h3{
                margin-bottom: 23px;
                color: $lt-blue;
                @include respond-below(lg){
                    margin-bottom: 15px;
                }
                @include respond-below(md){
                    margin-bottom: 23px;
                }
            }
            p{
                font-size: 20px;
                line-height: 1.5;
                font-weight: 400;
                margin-bottom: 12px;
                color: $grey;
                @include respond-below(xl) {
                    font-size: 18px;
                }
                @include respond-below(md) {
                    font-size: 16px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    &.right-img{
        flex-direction: row-reverse;
        .img-part{
            margin-left: -8.8%;
            max-width: 53.3%;
            margin-right: 0;
            transform: translateX(100%);
            opacity: 0;
            &.animate{
                transform: translateX(0);
                opacity: 1;
            }
            @include respond-below(md){
                max-width: 100%;
            }
        }
        .content-part{
            transform: translateX(-100%);
            opacity: 0;
            &.animate{
                transform: translateX(0);
                opacity: 1;
            }
        }
    }
    
}
.section-heading{
    h2{
        margin-bottom: 16px;
    }
    &.with-line{
        h2{
            padding-bottom: 20px;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 50px;
                height: 1px;
                background: #2961AA;
                margin: auto;
            }
        }
    }
}