.inner-banner{
    background: #183074;
    position: relative;
    padding: 60px 0 128px;
    background: #1160B0;
    background: linear-gradient(270deg, #1160B0 0%, #183074 100%);
    min-height: 380px;
    overflow: hidden;

    &.option{
        &-1{
            min-height: 360px;
            background: linear-gradient(270deg, #44BCED 0%, #1160B0 100%);
        }
        &-2{
            min-height: 360px;
            background: linear-gradient(270deg, #1160B0 0%, #183074 100%);
        }
    }
    @include respond-below(lg){
        padding: 40px 0 110px;
        min-height: 320px;
    }
    @include respond-below(md){
        padding: 50px 0 134px;
        overflow: hidden;
        min-height: 250px;
    }
    &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: image('inner-banner-bg.png') no-repeat center center;
        background-size: cover;
        mix-blend-mode: multiply;
        z-index: 0;
        @include respond-below(sm){
            background: image('listing-banner-bg-mb.png') no-repeat center center;
            background-size: cover;
        }
    }
    .banner-pattern{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        max-width: 472px;
        @include respond-below(xl){
            max-width: 400px;
        }
        @include respond-below(lg){
            max-width: 340px;
        }
        @include respond-below(md){
            max-width: 340px;
            bottom: -90px;
            right: -90px;
        }
    }
    &-wrap{
        z-index: 1;
        position: relative;
        .breadcum{
            margin-bottom: 45px;
            @include respond-below(lg){
                margin-bottom: 30px;
            }
            @include respond-below(md){
                margin-bottom: 13px;
            }
            ul{
                display: flex;
                flex-wrap: wrap;
                padding: 0;
                margin: 0 -14px;
                @include respond-below(md){
                    margin: 0 -16px;
                }
                li{
                    list-style: none;
                    padding: 0 14px;
                    position: relative;
                    @include respond-below(md){
                        padding: 0 16px;
                    }
                    &:not(:last-child){
                        &:after{
                            content: '';
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%) rotate(45deg);
                            width: 5px;
                            height: 5px;
                            border: 1px solid #F1C44E;
                            border-left: 0;
                            border-bottom: 0;
                            right: 0;
                            
                        }
                    }
                    a{
                        text-decoration: none;
                        letter-spacing: 1px;
                        color: $white;
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 1.83;
                        @include respond-below(md){
                            font-size: 10px;
                        }
                        &:hover{
                            color: $orange;
                        }
                    }
                }
            }
        }
        .banner-content{
            max-width: 580px;
            @include respond-below(lg){
                max-width: 430px;
            }
            @include respond-below(sm){
                max-width: 230px;
            }
            h1{
                margin-bottom: 3px;
                color: $white;
                @include respond-below(md){
                    margin-bottom: 0;
                }
            }
            p{
                color: #fff;
                font-size: 18px;
                line-height: 1.22;
                @include respond-below(md){
                    font-size: 16px;
                    line-height: 1.5;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 3;
                }
            }
        }
    }
    &.with-image{
        padding: 42px 0 40px;
        .inner-banner-wrap{
            display: flex;
            flex-wrap: wrap;
            .breadcum{
                margin-bottom: 23px;
                width: 100%;
                @include respond-below(md){
                    margin-bottom: 3px;
                }
            }
            .banner-content{
                max-width: 64%;
                padding-right: 30px;
                @include respond-below(lg){
                    max-width: 60%;
                }
                @include respond-below(sm){
                    max-width: 100%;
                    padding-right: 0;
                    width: 100%;
                }
                h1{
                    margin-bottom: 18px;
                    @include respond-below(md){
                        margin-bottom: 7px;
                    }
                }
                p{
                    font-size: 16px;
                    line-height: 1.37;
                    margin-bottom: 32px;
                    @include respond-below(sm){
                        margin-bottom: 20px;
                        max-width: 300px;
                    }
                }
                a.btn{
                    @include respond-below(sm){
                        width: 100%;
                    }
                }
            }
            .banner-img{
                width: 36%;
                margin-top: -40px;
                @include respond-below(lg){
                    width: 40%;
                }
                @include respond-below(md){
                    margin-top:0 ;
                }
                @include respond-below(sm){
                    display: none;
                }
                figure{
                    border-radius: 5px;
                    overflow:hidden;
                    img{
                        width: 100%;
                    }
                }
            } 
        } 
    }
}
.breadcum{
    margin-bottom: 45px;
    @include respond-below(lg){
        margin-bottom: 30px;
    }
    @include respond-below(md){
        margin-bottom: 13px;
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0 -14px;
        @include respond-below(md){
            margin: 0 -16px;
        }
        li{
            list-style: none;
            padding: 0 14px;
            position: relative;
            @include respond-below(md){
                padding: 0 16px;
            }
            &:not(:last-child){
                &:after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%) rotate(45deg);
                    width: 5px;
                    height: 5px;
                    border: 1px solid #F1C44E;
                    border-left: 0;
                    border-bottom: 0;
                    right: 0;
                    
                }
            }
            a{
                text-decoration: none;
                letter-spacing: 1px;
                color: #44BCED;
                font-weight: bold;
                font-size: 12px;
                line-height: 1.83;
                @include respond-below(md){
                    font-size: 10px;
                }
                &:hover{
                    color: $orange;
                }
            }
        }
    }
}
.landing{
    .inner-banner{
        min-height: 360px;
        background:linear-gradient(270deg, #44BCED 0%, #1160B0 100%);
        @include respond-below(md){
            min-height: 610px;
        }
        .banner-pattern{
            bottom: -220px;
            right: -276px;
            max-width: 772px;
            @include respond-below(lg){
                bottom: -150px;
                right: -276px;
                max-width: 560px;
            }
            @include respond-below(md){
                bottom: -167px;
                right: -213px;
                max-width: 474px;
                width: 474px;
            }
            svg{
                image{
                    transform: scale(1.3);
                    transform-origin: bottom;
                    transition: all .5s;
                }
            }
        }
        &-wrap{
            .banner-content{
                @include respond-below(md){
                    padding-top: 35px;
                }
                h1{
                    @include respond-below(md){
                        margin-bottom: 10px;
                    }
                }
                p{
                    font-family:"quatro";
                    font-size: 31px;
                    @include respond-below(lg){
                        font-size: 26px;
                    }
                    @include respond-below(md){
                        font-size: 16px;
                    }
                }
            } 
        } 
        &.animate{
            .banner-pattern{
                svg{
                    image{
                        transform: scale(1);
                    }
                }
            }   
        }
    }
}
.awards{
    .inner-banner{
        background: linear-gradient(0deg, #44BCED 0%, #1160B0 100%);
        &:after{
            background: url("../images/award-banner-bg.png") no-repeat center center;
            background-size: cover;
        }
        .banner-pattern{
            z-index: 1;
            max-width: 740px;
            top: -80px;
            right: -265px;
            @include respond-below(xl){
                max-width: 701px;
                top: -70px;
            }
            @include respond-below(lg){
                max-width: 540px;
                top: 0px;
            }
            @include respond-below(md){
                max-width: 305px;
                top: inherit;
                bottom: -98px;
                right: -160px;
            }
            img{
                width: 100%;
            }
        }
    }
}
.careers{
    .inner-banner{
        background: linear-gradient(270deg, #44bced 0%, #1160B0 100%);
    }
}
.contact{
    .inner-banner{
        padding: 60px 0;
        @include respond-below(md){
            padding: 60px 0 130px;
        }
        .inner-banner-wrap{
            .banner-content{
                max-width: calc(100% - 580px);
                @include respond-below(lg){
                    max-width: 100%;
                }
            }
        } 
    }
}