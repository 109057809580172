.case-study{
    &-section{
        padding: 68px 0 80px;
        @include respond-below(md){
            padding: 0 0 65px;
        }
    }
    &-wrap{
        max-width: 958px;
        margin: auto;
        .single-case-study{
            padding: 30px 0;
            border-bottom: 1px solid rgba(114,144,144,.3);
            @include respond-below(md){
                padding: 20px 0 15px;
            }
            &:first-child{
                padding-top: 0;
            }
            &:last-child{
                
                @include respond-below(md){
                    border-bottom: 1px solid rgba(114, 144, 144, 0.3);
                }
            }
            .single-case-wrap{
                display: flex;
                flex-wrap: wrap;
                margin: 0 -15px;
                @include respond-below(lg){
                    flex-direction: column-reverse;
                }
                .left-col{
                    width: 70.1%;
                    padding: 0 15px;
                    @include respond-below(lg){
                        width: 100%;
                    }
                    .small-label{
                        color: #44BCED;
                        margin-bottom: 20px;
                        display: block;
                        @include respond-below(lg){
                            margin-bottom: 20px;
                        }
                    }
                    h4{
                        line-height: 1.16;
                        margin-bottom: 12px;
                        color: #1C3270;
                        @include respond-below(md){
                            font-size: 25px;
                            margin-bottom: 42px;
                        }
                        a{
                            color: #1C3270;
                            &:hover{
                                color: $secondary-100;
                                text-decoration: none;
                            }
                        }
                    }
                    p{
                        @include respond-below(md){

                        }
                    }
                }
                .right-col{
                    padding: 0 15px;
                    width: 29.9%;
                    @include respond-below(lg){
                        width: 100%;
                        max-width: 295px;
                        padding-bottom: 22px;
                    }
                    @include respond-below(sm){
                        max-width: 100%;
                    }
                    figure{
                        position: relative;
                        padding-bottom: 66.4%;
                        @include imgFit;
                    }
                }
            }
        }
    }
}
.pagination{
    padding: 35px 0 0 0;
    ul{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        padding: 0;
        list-style: none;
        li{
            list-style: none;
            margin: 0 7px;
            padding: 0;
            color: $blue;
            font-size: 1.25rem;
            font-weight: 400;
            font-size: 16px;
            a{
                text-decoration: none;
                &:hover{
                    color: $orange;
                }
            }
            &.prev-nav{
                margin-right: 37px;
                font-weight: 500;
                font-size: 21px;
                a{
                    padding-left: 15px;
                    color: $blue;
                    position: relative;
                    &:before{
                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%) rotate(-45deg);
                        width: 7px;
                        height: 7px;
                        border: 2px solid $blue;
                        border-right: 0;
                        border-bottom: 0;
                        left: 0;
                        transition: all .5s;
                    }
                    &:hover{
                        color: $orange;
                        &:before{
                            border-color: $orange;
                        }
                    }
                }
            }
            &.next-nav{
                margin-left: 37px;
                font-weight: 500;
                font-size: 21px;
                a{
                    padding-right: 15px;
                    color: $blue;
                    position: relative;
                    &:before{
                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%) rotate(135deg);
                        width: 7px;
                        height: 7px;
                        border: 2px solid $blue;
                        border-right: 0;
                        border-bottom: 0;
                        right: 0;
                        transition: all .5s;
                    }
                    &:hover{
                        color: $orange;
                        &:before{
                            border-color: $orange;
                        }
                    }
                }
            }
            &:nth-child(4){
                font-weight: 500;
            }
        }
    }
}