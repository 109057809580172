/*--- footer ---*/
.main-footer {
	width: 100%;
	height: auto;
	background: $white-800;
	line-height: normal;
	padding: 78px 0 70px;

	@include respond-below(xl){
		padding: 60px 0;
	}
	@include respond-below(md){
		padding: 55px 0 20px;
	}
	.desktop-footer{
		@include respond-below(md){
			display: none;
		}
	}
	.footer-wrap{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-right: 60px;
		border-bottom: 1px solid rgba(31, 3, 57, .09);
		padding-bottom:60px ;
		margin: 0 -10px;
		@include respond-below(xl){
			padding-bottom: 40px;
			padding-right: 0;
		}
		.footer-column{
			max-width: 25%;
			padding: 0 10px;
			h4{
				padding-bottom: 7px;
				margin-bottom: 16px;
				font-size: 20px;
				position: relative;
				line-height: 1.3;
				&:after{
					content: '';
					position: absolute;
					left: 0;
					bottom: 0;
					width: 50px;
					height: 1px;
					background: $orange;
				}
				a{
					text-decoration: none;
					display: inline-block;
					&:hover{
						color: $orange;
					}
				}
			}
			
			ul{
				list-style: none;
				padding-left: 0;
				margin-bottom: 16px;
				li{
					padding-bottom: 12px;
					padding-top: 0;
					a{
						font-size: 14px;
						line-height: 1.2;
						text-decoration: none;
						color: $blue;
						&:hover{
							color: $orange;
						}
					}
				}
			}
		}
	}
	.bottom-footer{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-top: 50px;
		margin: 0 -10px;
		@include respond-below(xl){
			padding-top: 40px;
		}
		.footer-logo-wrap{
			display: flex;
			justify-content: space-between;
			width: 50%;
			margin: 0 -10px;
			align-items: center;
			@include respond-below(lg){
				width: 60%;
			}
			.footer-logo{
				max-width: 171px;
    			padding: 0 10px;
			}
			.copyright{
				padding: 0 10px;
				span{
					font-size: 10px;
					display: block;
					margin-bottom: 5px;
					color: #807DB5;
					&:last-of-type{
						margin-bottom: 0;
					}
				}
				a{
					color: #807DB5;
					text-decoration: none;
					&:hover{
						color: $orange;
					}
				}
			}
		}
		.social-wrap{
			width: 50%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-right: 81px;
			@include respond-below(xl){
				padding-right: 0;
			}
			@include respond-below(lg){
				width: 40%;
			}
			.social-icons{
				width: calc(100% - 130px);
				text-align: center;
				padding-left: 85px;
				@include respond-below(lg){
					padding-left: 0;
				}
				ul{
					list-style: none;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0;
					margin: 0;
					li{
						padding: 0 20px;
						@include respond-below(lg){
							padding: 0 10px;
						}
						a{
							&:hover{
								filter: grayscale(1);
							}
						}
					}
				}
			}
			.contact-btn{
				a{
					background: #41C0B8;
					&:hover{
						background: #2a9690;
					}
				}
			}
		}
	}
	.footer-mobile{
		@include respond-above(md){
			display: none;
		}
		ul{
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;
			li{
				padding: 0 0 20px;
				a{
					text-decoration: none;
				}
			}
		}
		.footer-menu{
			li{
				a{
					font-size: 20px;
					position: relative;
					padding-bottom: 3px;
					display: inline-block;
					color: $blue;
					font-weight: 500;
					&:after{
						content: '';
						position: absolute;
						left: 0;
						right: 0;
						width: 44px;
						height: 1px;
						background-color: $orange;
						margin: auto;
						bottom: 0;
					}
					&:hover{
						color: $orange;
					}
				}
			}
		}
		.footer-logo{
			padding-top: 5px;
			max-width: 150px;
			margin: auto;
			padding-bottom: 25px;
		}
		.copyright{
			padding-bottom: 10px;
			span,a {
				font-size: 12px;
				line-height: 17px;
				color: #807DB5;
			}
			span{
				display: block;
				margin-bottom: 15px;
			}
			a{
				text-decoration: none;
				&:hover{
					color: $orange;
				}
			}
		}
		.social-icons{
			padding-bottom: 25px;
			ul{
				display: flex;
				align-items: center;
				justify-content: center;
				li{
					padding: 0 15px;
					a{
						&:hover{
							filter: grayscale(1);
						}
					}
				}
			}
		}
		.contact-btn{
			a{
				background: #41C0B8;
				&:hover{
					background: #2a9690;
				}
			}
		}
	}
}
