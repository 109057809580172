.cta-promo{
    padding: 80px 0 55px;
    background: rgb(24,48,116);
    background: linear-gradient(90deg, rgba(24,48,116,1) 0%, rgba(17,96,176,1) 100%);
    position: relative;
    overflow: hidden;
    @include respond-below(lg){
        padding: 60px 0 50px;
    }
    @include respond-below(md){
        padding: 45px 0;
    }

    &::after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(../images/cta-bg-pattern.png);
        mix-blend-mode: multiply;
        z-index: 0;
        background-size: cover;
    }
    .cta-pattern{
        position: absolute;
        left: -152px;
        bottom: 5px;
        z-index: 1;
        max-width: 454px;
        @include respond-below(xl){
            max-width: 440px;
        }
        @include respond-below(lg){
            max-width: 350px;
        }
        @include respond-below(md){
            display: none;
        }
        img.load-img{
            position: absolute;
            transition: all .5s;
        }
    }
    .cta-promo-wrap{
        max-width: 720px;
        margin: auto;
        position: relative;
        z-index: 1;
        transform:translateX(50px);
        opacity: 0;
        transition: all .5s;
        @include respond-below(xl){
            max-width: 100%;
            padding-left: 260px;
        }
        @include respond-below(lg){
            padding-left: 165px;
        }
        @include respond-below(md){
            padding-left: 0;
            text-align: center;
        }
        h2{
            color: $white;
            margin-bottom: 20px;
            @include respond-below(md){
                margin-bottom: 10px;
            }
        }
        p{
            font-size: 24px;
            line-height: 1.33;
            color: $white;
            margin-bottom: 15px;
            @include respond-below(xl){
                font-size: 20px;
            }
            @include respond-below(lg){
                font-size: 18px;
            }
            @include respond-below(md){
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 20px;
            }
        }
    }
    &.animate{
        .cta-promo-wrap{
            opacity: 1;
            transform: translateX(0);
        }
        .cta-pattern{
            img.load-img{
                opacity: 0;
            }
        } 
    }
}