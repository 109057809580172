.two-column-cta-with-image{
    padding: 88px 0 50px;
    @include respond-below(md){
        padding: 30px 0 70px;
    }
    .wrap-block{
        display: flex;
        margin: 0 -15px;
        flex-wrap: wrap;
        .single-column{
            width: 50%;
            padding: 0 15px;
            @include respond-below(md){
                margin-bottom: 50px;
                width: 100%;
                &:last-child{
                    margin-bottom: 00px;
                }
            }
            .single-column-wrap{
                max-width: 362px;
                margin: 0 auto;
                .image-block{
                    figure{
                        position: relative;
                        padding-bottom: 66.6%;
                        @include imgFit;
                    }
                }
                .content-block{
                    padding: 30px 0 0;
                    text-align: center;
                    h3{
                        margin-bottom: 7px;
                        @include respond-below(md){
                            font-size: 31px;
                        }
                    }
                    p{
                        line-height: 1.37; 
                    }
                }
            }
        }
    }
}