%btn {
	display: inline-block;
	padding: rem(14px) rem(30px);
	vertical-align: top;
	color: $white;
	font-size: $font-size-base;
	font-weight: 600;
	line-height: 1.5;
	text-align: center;
	text-decoration: none;
	border: none;
	border-radius: 0;
	background-color: $primary-100;
	transition: background-color 0.3s ease 0s, color 0.3s ease 0s, border-color 0.3s ease 0s;

	&:hover,
	&:focus,
	a:hover & {
		color: $white;
		text-decoration: none;
		background-color: #b74931;
		cursor: pointer;
	}
}
.btn {
	@extend %btn;
	&-link {
		margin-bottom: rem(2px);
		font-size: $font-size-base;
		text-decoration: none;
		border-bottom: 1px solid transparent;
		transition: background-color 0.3s ease 0s, color 0.3s ease 0s, border-color 0.3s ease 0s;
		font-weight: 600;
		color: $orange;
		&::after{
			content: '';
			background-image: url(../images/arrow-right.svg);
			width: 18px;
			height: 14px;
			background-size: cover;
			display: inline-block;
			margin-left: 10px;
			top: 2px;
			position: relative;
			transition: all .5s;
		}

		&:hover,
		&:focus,
		a:hover & {
			color: $orange;
			text-decoration: none;
			&::after{
				margin-left: 16px;
			}
		}
	}
}

input[type="button"],
input[type="reset"],
input[type="submit"] {
	@extend %btn;
	-webkit-appearance: button;
}
