form label {
	display: block;
	margin-bottom: rem(10px);
	line-height: 1.5;
	font-weight: $label-font-weight;
	font-family: $label-font-family;
	font-size: $label-font-size;
	color: $label-color;
}

input,
textarea,
button,
select {
	font-family: $input-font-family;
}

input[type="url"],
input[type="text"],
input[type="submit"],
input[type="button"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="date"],
textarea {
	-webkit-appearance: none;
	outline: none;
}

input[type="url"],
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="date"],
textarea,
select {
	width: 100%;
	height: $input-height-base;
	padding: 0 rem(8px);
	color: $input-color;
	font-size: $input-font-size;
	font-weight: $input-font-weight;
	border: rem(1px) solid $input-border-color;
	border-radius: $input-radius;
	letter-spacing: 0.5px;

	&:focus {
		border-color: $input-border-color-focus !important;
		outline: none;
	}
	@include placeholder {
		color: $input-color-placeholder;
		opacity: 1;
		text-transform: uppercase;
	}
}

textarea {
	display: block;
	height: $textarea-height-base;
	padding: rem(10px) rem(15px);
	resize: none;
	overflow: auto;
}

select {
	-webkit-appearance: none;
	padding-right: 1.875em;
	background-color: transparent;
	background-image: image("select-down-arrow2.svg");
	background-size: rem(27px) rem(15px);
	background-position: right 57%;
	background-repeat: no-repeat;
	cursor: pointer;
	text-transform: uppercase;
	option{
		text-transform: initial;
	}
}

select:focus {
	outline: none;
}

/* for ie */
select::-ms-expand {
	display: none;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	// border: 1px solid green;
	// -webkit-text-fill-color: green;
	// -webkit-box-shadow: 0 0 0px 1000px #000 inset;
	transition: background-color 5000s ease-in-out 0s;
}

.form-group {
	margin-bottom: rem(20px);
}

// Custom radio button
.radio-btn-group {
	.radio-option {
		display: inline-block;
		vertical-align: middle;
		padding: 0 rem(10px);
		&:first-child {
			padding-left: 0;
		}
		.radio-custom {
			opacity: 0;
			position: absolute;
			&:focus {
				+ .radio-custom-label {
					outline: none;
				}
			}
			&:checked {
				+ .radio-custom-label {
					&:before {
						background-color: $black;
					}
				}
			}
		}
		.radio-custom-label {
			margin: 0;
			cursor: pointer;
			color: $black;
			&:before {
				content: "";
				display: inline-block;
				vertical-align: middle;
				height: rem(10px);
				width: rem(10px);
				margin-top: rem(3px);
				margin-right: rem(10px);
				background-color: $white;
				border: rem(2px) solid $white;
				border-radius: 50%;
				box-shadow: 0 0 0px 2px $black;
				cursor: pointer;
			}
		}
	}
}
// Custom Checkbox
.checkbox,
.gfield_checkbox {
	input[type="checkbox"] {
		display: none;
		width: initial;
		height: initial;
		margin-bottom: 0;
		padding: 0;
		cursor: pointer;
		&:checked + label:after {
			content: "";
			position: absolute;
			top: 4px;
			left: 7px;
			display: block;
			width: rem(5px);
			height: rem(10px);
			border: solid $secondary-100;
			border-width: 0 rem(2px) rem(2px) 0;
			transform: rotate(42deg);
		}
	}
	label {
		position: relative;
		cursor: pointer;
		&::before {
			content: "";
			position: relative;
			display: inline-block;
			vertical-align: middle;
			margin-right: rem(8px);
			padding: rem(8px);
			background-color: transparent;
			border: rem(2px) solid $secondary-100;
			-webkit-appearance: none;
			cursor: pointer;
		}
	}
}
