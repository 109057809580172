.related-insight{
    padding: 43px 0 107px;
    @include respond-below(xl){
        padding: 40px 0 80px;
    }
    @include respond-below(lg){
        padding: 60px 0 80px;
    }
    @include respond-below(md){
        padding: 60px 0 40px;
    }
    .section-heading{
        padding-bottom: 22px;
        @include respond-below(lg){
            padding-bottom: 16px;
        }
        h2{
            padding-bottom: 0;
            @include respond-below(md){
                padding-bottom: 20px;
            }
            &:after{
                display: none;
                @include respond-below(md){
                    display: block;
                    background: #E86849;
                }
            }
        }
    }

    .blogs-wrap{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        transform: translateY(100px);
        opacity: 0;
        &.animate{
            transform: translateY(0);
            opacity: 1;
        }
        @include respond-below(lg){
            margin: 0 -10px;
        }
        .single-blog{
            width: 33.33%;
            padding: 0 15px;
            @include respond-below(lg){
                padding: 0 10px;
            }
            @include respond-below(md){
                width: 100%;
                margin-bottom: 32px;
            }
            .blog-image{
                figure{
                    position: relative;
                    padding-bottom: 66.7%;
                   @include imgFit;
                   &:after{
                       content: '';
                       position: absolute;
                       left: 0;
                       top: 0;
                       width: 100%;
                       height: 100%;
                       background: rgba(17, 96, 176,.41);
                   }
                }
            }
            .blog-content{
                padding: 30px 33px;
                box-shadow: 0 3px 26px rgba($body-color ,.20);
                @include respond-below(lg){
                    padding: 30px 15px;
                }
                @include respond-below(md){
                    padding: 27px 20px;
                }
                h5{
                    margin-bottom: 4px;
                    color: #44BCED;
                    font-size: 14px;
                    line-height: 1.4;
                    font-weight: bold;
                    @include respond-below(md){
                        
                    }
                }
                h4{
                    color: $blue;
                    font-size: 20px;
                    line-height: 1.5;
                    color: #2961aa;
                    margin-bottom: 0;
                    font-weight: 500;
                    @include respond-below(lg){
                        font-size: 18px;
                    }
                    @include respond-below(md){
                        font-size: 20px;
                    }
                    a{
                        text-decoration: none;
                        &:hover{
                            color: $secondary-100;
                        }
                    }
                }
            }
        }
    }
}
