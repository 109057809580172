.leadership{
    @include respond-below(sm){
        .inner-banner-wrap{
            .banner-content{
                max-width: 240px;
            }
        } 
    }
    .text-only-5050{
        padding: 70px 0 36px;
        @include respond-below(md){
            padding: 40px 0 30px;
        }
        .inner-block-5050{
            max-width: 960px;
            .left-col{
                width: 28%;
                @include respond-below(lg){
                    width: 30%;
                }
                @include respond-below(md){
                    width: 100%;
                }
            }
            .right-col{
                width: 72%;
                padding: 0 0 0 30px;
                @include respond-below(lg){
                    width: 70%;
                }
                @include respond-below(md){
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }
}
.leadership-section{
    padding: 0 0 80px;
    position: relative;
    overflow: hidden;
    @include respond-below(md){
        padding: 30px 0 40px;
    }
    .circle-image{
        position: absolute;
        bottom: -30%;
        right: -11%;
        max-width: 741px;
        // img{
        //     filter: grayscale(1);
        // }

        @include respond-below(md){
            bottom: -490px;
            right: -407px;
        }
    }
    .leadership-wrap{
        max-width: 838px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        padding-right: 58px;
        @include respond-below(xl){
            padding-right: 0;
        }
        @include respond-below(sm){
            flex-direction: column;
        }
        .single-member{
            padding: 0 15px;
            margin-bottom: 38px;
            max-width: 195px;
            width: 100%;
            @include respond-below(lg){
                max-width: 33.33%;
            }
            @include respond-below(md){
                max-width: 50%;
            }
            @include respond-below(sm){
                max-width: 196px;
                margin: 0 auto 30px;
            }
            .member-image{
                position: relative;
                figure{
                    position: relative;
                    padding-bottom: 100%;
                    border-radius: 5px;
                    overflow: hidden;
                    @include imgFit;
                }
                .plus-icon{
                    a{
                        width: 31px;
                        height: 31px;
                        background: #177BC6;
                        border-radius: 50%;
                        position: relative;
                        padding: 6px;
                        cursor: pointer;
                        position: absolute;
                        bottom: 16px;
                        right: 20px;
                        &:before, &:after{
                            content: '';
                            position: absolute;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            background: #fff;
                            top: 50%;
                        }
                        &:before{
                            width: 3px;
                            height: 17px;
                        }
                        &:after{
                            width: 17px;
                            height: 3px;
                        }
                    }
                }
            }
            .member-content{
                padding: 26px 0 0 0;
                .social-icons{
                    padding-bottom: 15px;
                    ul{
                        padding: 0;
                        margin: 0;
                        list-style: none;
                        display: flex;
                        flex-wrap: wrap;
                        li{
                            padding: 0;
                            margin: 0 10px 0 0;
                            max-width: 20px;
                            a{
                                display: block;
                                line-height: 15px;
                            }
                        }
                    }
                }
                .member-name{
                    color: #183074;
                    margin-bottom: 5px;
                    @include respond-below(md){
                        font-size: 20px;
                    }
                }
                .position{
                    font-size: 14px;
                    color: #44BCED;
                    font-weight: 500;
                }
                .location{
                    font-size: 14px;
                    color: #707070;
                    letter-spacing: -0.2px;
                }
            }
        }
    }
}
.leader-member-popup{
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background: #1468A5;
    background:linear-gradient(0deg, #1468A5 0%, #093767e3 100%);
    transition: all .5s;
    padding: 15px;
    &.active{
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }
}
.member-popup{
    max-width: 1158px;
    padding: 70px 75px 55px 30px;
    border-radius: 5px;
    background: #fff;
    position: relative;
    width: 100%;
    max-height: 100%;
    overflow:auto ;
    @include respond-below(xl){
        padding: 70px 16px 40px 16px;
    }
    @include respond-below(md){
        padding:77px 8px 27px 8px;
    }
    .control{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        position: absolute;
        top: 0;
        right: 0;
        justify-content: flex-end;
        align-items: center;
        padding: 25px 19px 0 0;
        .slick-arrow{
            width: auto;
            height: auto;
            position: static;
            font-size: 14px;
            font-weight: 500;
            position: relative;
            background: #807DB5;
            padding: 5px 18px;
            margin-right: 13px;
            margin-bottom: 0;
            border:none;
            &.prev{
                span{
                    padding-left: 13px;
                    position: relative;
                    padding-right: 0px;
                    &:before{
                        transform: translateY(-50%) rotate(225deg);
                        left: 0;
                        right: inherit;
                    }
                }
            }
            span{
                position: relative;
                padding-right: 13px;
                color: #fff;
            &:before{
                content: '';
                position: absolute;
                right: 0;
                top: 45%;
                transform: translateY(-50%) rotate(45deg);
                width: 6px;
                height: 6px;
                border: 2px solid #fff;
                border-left: 0;
                border-bottom: 0;
            }
        }
        &:after{
            display: none;
        }
        }
    }
    
    .member-popup-wrap{
        display: flex;
        flex-wrap: wrap;
        background: #fff;
        padding: 0 14px;
        .popup-image{
            width: 28.5%;
            @include respond-below(md){
                width: 100%;
                max-width: 298px;
                padding-bottom: 19px;
            }
            figure{
                position: relative;
                padding-bottom: 100%;
                box-shadow: 0 3px 16px rgba(0,0,0,.16);
                @include imgFit;
            }
        }
    }
    .popup-content{
        width: 71.5%;
        padding-left: 30px;
        padding-top: 0;
        @include respond-below(md){
            width: 100%;
            padding-left: 0;
        }
        .social-name{
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 3px;
            @include respond-below(md){
                padding-bottom: 0;
            }
        }
        .social-icons{
            padding-right: 8px;
            ul{
                padding: 0;
                margin: 0;
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                li{
                    padding: 0;
                    margin: 0 8px 0 0;
                    max-width: 20px;
                    a{
                        display: block;
                        line-height: 15px;
                    }
                }
            }
        }
        .member-name{
            color: #183074;
            margin-bottom: 0px;
            @include respond-below(md){
                font-size: 20px;
            }
        }
        .position{
            font-size: 16px;
            color: #44BCED;
            font-weight: 500;
            margin-bottom: 8px;
            @include respond-below(md){
                margin-bottom: 4px;
            }
        }
        p{
            color: $body-color;
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0px;
            }
        }
    }
    .close-popup{
        width: 32px;
        height: 32px;
        background: #177BC6;
        position: relative;
        border-radius: 50%;
        cursor: pointer;
        svg{
            display: none;
        }
        &:before, &:after{
            content: '';
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            background: #fff;
            top: 50%;
            transition: all .5s;
        }
        &:before{
            width: 3px;
            height: 17px;
        }
        &:after{
            width: 17px;
            height: 3px;
        }
        &:hover{
            &:before, &:after{
                background: $orange;
            }
        }
    }
}
.popup-modal{
    .fancybox__backdrop{
        background: #1468A5;
        background:linear-gradient(0deg, #1468A5 0%, #093767 100%);
        opacity: 0.89;
    }
    .fancybox__slide{
        padding: 48px 16px 8px 16px;
    }
}