/*--- header ---*/
.wrapper{
    padding-top: 104px;
    @include respond-below(md){
        padding-top: 55px;
    }
}
.main-header, .fixed-header {
	position: fixed;
	width: 100%;
	height: auto;
	z-index: 999;
	top: 0;
	left: 0;
    background: $white;
    transition: all .5s;
	@include respond-below(md){
		background: $white;
		padding: 12px 0;
	}
	.container{
		max-width: 1448px ;
		@include respond-below(md){
			align-items: center;
		}
	} 
	.brand {
        width: 20%;
        max-width: rem(140px);
        padding-bottom: 35px;

        @include respond-below(xl) {
            max-width: rem(150px);
        }

        @include respond-below(lg) {
            max-width: rem(127px);
        }

        @include respond-below(md) {
            width: 50%;
			max-width: 100px;
            padding-bottom: 0px;
        }
		.mobile-logo{
			display: block;
			@include respond-below(md){
				display: block;
			}
		}
		.desktop-logo{
            display: none;
			@include respond-below(md){
				display: none;
			}
		}
    }
	.header-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 80%;
        padding: 16px 18px 0px 0;

        @include respond-below(xl) {
			padding-right: 0;
		}
		

        @include respond-below(md) {
            width: 50%;
			padding: 0;
        }
    }
	/* nav */
	.navigation {
		word-break: keep-all;
		ul {
			display: flex;
            align-items: center;
            padding: 0;
            margin: 0;
            list-style: none;

			li {
				padding: 0;
                list-style: none;
                &:not(.mega-menu){
                    position: relative;
                }

				a {
					text-decoration: none;
					color: $blue;
					&:hover{
						color: $orange !important;
					}
				}
			}
		}
	}
	.utility-menu {
        display: flex;
        align-items: center;

		.search-wrap{
			line-height: 16px;
			@include respond-below(md){
				line-height: 19px;
				margin-right: 25px;
			}
			cursor: pointer;
			.icon-search{
				svg{
					vertical-align: middle;
					path{
                        fill: #1279C2;
                        transition: all .5s;
						@include respond-below(md){
							fill: #1160B0;
						}
					}
                    &:hover{
                        path{
                            fill: $orange !important;
                        }
                    }
				}
			}
		}

        .utility-navigation {
			padding-right: 5px;
			@include respond-below(md){
				display: none;
			}
            li {
                margin: 0 rem(15px);

                a {
                    font-size: rem(14px);
                    position: relative;
                    color: #9F9F9F;
                    &:hover{
                        color: $orange !important;
                    }
                }
            }
        }
    }
	.main-navigation {
        display: flex;
        align-items: center;
		padding-top: 5px;
		@include respond-below(md){
			display: none;
		}

    //     @include respond-below(lg) {
    //         display: none;
    //     }
		ul {
            li {
                margin: 0 rem(16px);
                font-weight: 500;
                padding-bottom: 35px;

    //             @include respond-below(xl) {
    //                 margin: 0 rem(8px);
    //                 font-size: $font-size-sm;
    //             }
				a{
					font-size: 16px;
					font-weight: 500;
				}
				&:last-child{
					margin-right: 5px;
				}
                &:hover{
                    .mega-menu-wrapper{
                        opacity: 1;
                        visibility: visible;
                        pointer-events: fill;
                    }
                }
                &.submenu{
                    .sub-menu{
                        position: absolute;
                        top: 95%;
                        left: 0;
                        background: #fff;
                        width: 368px;
                        display: block;
                        box-shadow: 0 12px 32px rgb(0 0 0 / 24%);
                        padding: 50px 70px 75px 50px;
                        opacity: 0;
                        visibility: hidden;
                        pointer-events: none;
                        transition: all .5s;
                        @include respond-below(xxl){
                            width: 350px;
                            padding: 50px 50px 60px 40px;
                        }
                        @include respond-below(xl){
                            width: 330px;
                            padding: 40px 40px 40px 30px;
                        }
                        span{
                            margin-bottom: 14px;
                            display: block;
                            font-size: 16px;
                            font-weight: 500;
                            color: $orange;
                        }
                        li{
                            padding-bottom: 16px;
                            margin: 0;
                            padding-left: 20px;
                            .submenuToggle{
                                position: absolute;
                                top: 6px;
                                right: 0;
                                width: 8px;
                                height: 8px;
                                border:2px solid #1C3270;
                                border-left: 0;
                                border-bottom: 0;
                                transform: rotate(45deg);
                            }
                            a{
                                font-size: 16px;
                                color: $blue;
                                font-weight: 400;
                                &:hover{
                                    font-weight: 500;
                                    color: $blue !important;
                                }
                            }
                        }
                    }
                    &:hover{
                        .sub-menu{
                            opacity: 1;
                            visibility: visible;
                            pointer-events: all;
                        }
                    }
                }
                &:nth-last-child(2), &:nth-last-child(1){
                    .sub-menu{
                        right: 0;
                        left: inherit;
                    }
                }
			}
		}
	}

	/* Search Form */
    .search-form {
        &-box {
            position: absolute;
            left: 0;
            top: 0;
            // display: none;
            width: 100%;
            height: 100vh;
            z-index: 9999;
			display: none;
        }

        &-backdrop {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(64,64,64, 0.49);
        }

        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100vh;

            // @media (max-width: 767px) and (min-height: 450px) {
            //     height: 50vh;
            // }
        }

        &-inner {
            display: flex;
            align-items: center;
            max-width: rem(677px);
            width: calc(100% - 40px);
            z-index: 1;
			max-width: 1053px;
			border-radius: 8px;
			background: $white;
			padding: 62px 48px;
			position: relative;
			@include respond-below(md){
				padding: 40px 20px;
			}

            .close-form {
                position: absolute;
                width: 20px;
                color: $white;
                font-size: rem(24px);
                line-height: 1;
                cursor: pointer;
                transition: 0.3s;
				top: 23px;
				right: 27px;

                @include respond-below(md) {
                    position: absolute;
                    top: 10px;
                    right: rem(15px);
                    display: flex;
                    height: rem(22px);
                    width: rem(20px);
                    font-size: 0;
                    padding: 0;
                    margin: 0;
                }

                &:hover {

                    &:before,
                    &:after {
                        background-color: $blue;
                    }
                }

                &:before,
                &:after {
                    position: absolute;
                    left: rem(9px);
                    top: 0;
                    content: " ";
                    height: rem(24px);
                    width: rem(2px);
                    background-color: #1160B0;
                    transition: 0.3s;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }

            .search-form {
                position: relative;
                display: flex;
                align-items: center;
                justify-self: center;
                width: 100%;
                z-index: 9;

                @include respond-below(md) {
                    width: 100%;
                }

                .search-field {
                    padding-left: rem(17px);
                    padding-right: rem(70px);
                    height: rem(56px);
                    color: $grey;
					font-size: 25px;
                    background-color: $white;
                    border-color: #303F54;
					@include respond-below(md){
						font-size: 20px;
					}

                    @include placeholder {
                        color: $grey;
                        opacity: 1;
                    }
                }

                .search-submit {
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: rem(56px);
                    width: rem(70px);
                    min-width: auto;
                    font-size: 0;
                    padding: rem(10px);
                    border: 2px solid transparent;
                    background-image: image("bue-search-icon.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: rem(20px);
					background-color: transparent;
                }
            }
        }
    }
}

body.home{
    .wrapper{
        padding-top: 0;
    }
    .main-header{
        background: transparent;
        .brand{
            .desktop-logo{
                display: block;
            }
            .mobile-logo{
                display: none;
            }
        }
        .utility-menu{
            .utility-navigation{
                li{
                    a{
                        color: $white;
                    }
                } 
            }
            .search-wrap{
                .icon-search{
                    svg{
                        path {
                            fill: $white;
                        }
                    } 
                } 
            } 
        } 
        .main-navigation{
            ul{
                li{
                    a{
                        color: $white;
                        
                    }
                } 
            } 
        }
        &.fixed-header{
            background: $white;
            .brand{
                .desktop-logo{
                    display: none;
                }
                .mobile-logo{
                    display: block;
                }
            }
            .utility-menu{
                .utility-navigation{
                    li{
                        a{
                            color: #9F9F9F;
                        }
                    } 
                }
                .search-wrap{
                    .icon-search{
                        svg{
                            path {
                                fill: #1279C2;
                            }
                        } 
                    } 
                } 
            } 
            .main-navigation{
                ul{
                    li{
                        a{
                            color: $blue;
                            
                        }
                    } 
                } 
            }
        } 
    }
    
}

.mega-menu-wrapper{
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    background: $white;
    padding: 40px 0 66px;
    box-shadow: 0 12px 32px rgba($black, .24);
    opacity: 0;
    visibility: hidden;
    transition: all .5s;
    pointer-events: none;
    .mega-menu-container{
        max-width: 1292px;
        margin: auto;
    }
    .sub-menu{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        @include respond-below(xl){
            margin: 0;
        }
        .left-col{
            padding: 0 15px;
            width: 75%;
            .menu-name{
                span{
                    font-size: 16px;
                    color: $orange;
                    text-transform: uppercase;
                    display: block;
                }
            }
            .mega-menu-tab{
                padding-left: 30px;
                display: flex;
                flex-wrap: wrap;
                margin: 0 -15px;
                padding-top: 15px;
                .tab-items{
                    width: 30%;
                    padding: 0 15px;
                    ul{
                        flex-wrap: wrap;
                        position: relative;
                        li{
                            width: 100%;
                            margin: 0;
                            padding-bottom: 20px;
                            position: static;
                            a{
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba($blue, .5);
                            }
                            &.show{
                                .inner-tab-content{
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }
                // .menu-tab-content{
                //     width: 70%;
                //     padding: 0 15px;
                    .inner-tab-content{
                        display: flex;
                        flex-wrap: wrap;
                        padding-right: 80px;
                        margin: 0 -15px;
                        padding-left: 34px;
                        position: absolute !important;
                        left: 100%;
                        width: 100%;
                        min-width: 706px;
                        top: 0;
                        opacity: 0;
                        visibility: hidden;
                        transition: all .5s;
                        @include respond-below(xl){
                            min-width: 53vw;
                            padding-right: 0;
                            padding-left: 15px;
                        }
                        .tab-items{
                            width: 50%;
                            padding: 0 15px;
                            margin-bottom: 25px;
                            a{
                                color: #41c0bb;
                                font-size: 16px;
                                margin-bottom: 4px;
                                display: inline-block;
                            }
                        }
                    }
                // }
            }
        }
        .right-col{
            padding: 0 15px;
            width: 25%;
            .right-col-wrap{
                max-width: 247px;
                .sub-menu-blog{
                    box-shadow: 0 3px 26px rgba($body-color, .2);
                    border-radius: 5px;
                    .blog-image{
                        figure{
                            position: relative;
                            padding-bottom: 51.1%;
                            @include imgFit;
                        }
                    }
                    .blog-content{
                        padding: 18px 15px 15px;
                        span{
                            color: #44BCED;
                            display: block;
                            font-size: 10px;
                            font-weight: bold;
                            margin-bottom: 7px;
                        }
                        p{
                            font-size: 16px;
                            line-height: 1.43;
                            font-weight: 500;
                            color: #2961aa;
                        }
                    }
                }
            }
        }
    }
}

body.home{
    &.scroll-fixed{
    .main-header{
        .brand{
            .desktop-logo{
                @include respond-below(md){
                    display: none;
                }
            }
            .mobile-logo{
                @include respond-below(md){
                    display: block;
                }
            }
        } 
    } 
    }
}